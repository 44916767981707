import { Checkbox, Tooltip } from "antd";
import { FilterRealStocks } from "features/Campaign/components/Detail/ProductPromo/components/FilterProduct";
import { CAMPAIGN_TYPE, PRODUCT_TYPE } from "features/Campaign/constants";
import { useGetProdAttribute } from "hooks/attribute";
import { useCheckDuplicateCodes } from "hooks/campaign";
import { useGetManufacturers } from "hooks/manufacturer/manufacturer";
import { useGetAllCategories, useGetProducts } from "hooks/product/product";
import useDebounce from "hooks/useDebounce";
import { useWarehouse } from "hooks/warehouse";
import { t } from "i18next";
import { flatten, isEqual, min, uniq } from "lodash";
import { FilterAttributes } from "pages/App/Product/Product/List/components/FilterProduct";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { endOfMomentToISOString, startOfMomentToISOString } from "utils/dateTime";
import { getQuery, notify } from "utils/helperFuncs";

function useModalSelectProduct({
  form,
  indexPromotionCampaignTiers,
  indexConditionProductGroups,
  isUpdate,
  dataProductsServer,
  indexComboDiscountMoney,
  isCheckboxType,
  indexComboFixedPrice,
  handleSelectedProducts,
}) {
  const formValues = form.getFieldsValue();
  const { id } = useParams();
  const GET_QUERY = getQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedValue = useDebounce(searchTerm, 500);
  const [isModalSelectProduct, setIsModalSelectProduct] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [dataDuplicateCode, setDataDuplicateCode] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const { warehouses: listWarehouse, loadingWarehouses } = useWarehouse();
  const { dataManufactures, loadingManufactures } = useGetManufacturers();
  const { loading: loadingCheckDuplicateCodes, handleCheckDuplicateCodes } = useCheckDuplicateCodes();
  const { data: dataAllCategories } = useGetAllCategories();
  const { warehouses } = useWarehouse();

  const getQueryFilter = () => {
    return GET_QUERY.query ? `${GET_QUERY.query}` : undefined;
  };

  const dataProductsKeyServer = dataProductsServer?.map((item) => item?.id);

  const getDefaultParams = () => ({
    filters: {
      query: getQueryFilter(),
      categoryIDs: GET_QUERY.categoryIDs || undefined,
      manufacturerIDs: GET_QUERY.manufacturerIDs || undefined,
      priceLevel1: GET_QUERY.priceLevel1 ? JSON.parse(GET_QUERY.priceLevel1) : undefined,
      realStocks: GET_QUERY.realStocks ? GET_QUERY.realStocks?.map((item) => JSON.parse(item)) : undefined,
      vat: GET_QUERY.vat,
      vatPercent: GET_QUERY.vatPercent ? JSON.parse(GET_QUERY.vatPercent) : undefined,
      sellingStatuses: GET_QUERY.vatPercent ? JSON.parse(GET_QUERY.sellingStatuses) : undefined,
      attributeValues: GET_QUERY.attributeValues
        ? GET_QUERY.attributeValues?.map((item) => JSON.parse(item))
        : undefined,
      isActive: true,
    },
    pagination: {
      limit: GET_QUERY.limit || 5,
      offset: GET_QUERY.offset || 0,
    },
  });

  const [params, _setParams] = useState(getDefaultParams());

  const countSelectedRowKeys = selectedRowKeys?.length ? selectedRowKeys?.length : 0;

  const paramsRef = useRef(params);

  const setParams = (data) => {
    paramsRef.current = data;
    _setParams(data);
  };

  const getParams = () => ({
    ...params,
  });

  const hasCategory = getParams().filters.categoryIDs;

  const { data: productData, loading: loadingProduct, paginationData, refetch } = useGetProducts(getParams());
  const { data: prodAtt } = useGetProdAttribute(hasCategory);

  const filterItemsTypeOptions = [
    { label: t("common.all"), value: false },
    { label: `${t("cart.selected")} (${countSelectedRowKeys})`, value: true },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedRowKeys,
    preserveSelectedRowKeys: true,
    hideSelectAll: !isFilterSelected && true,
  };

  // check type table
  if (isCheckboxType) {
    rowSelection.renderCell = (checked, record, index, originNode) => {
      return (
        <Tooltip title={selectedRows?.[0]?.categoryID !== record?.categoryID && t("campaign.combo.alternatively")}>
          <Checkbox
            {...originNode.props}
            checked={[...(selectedRows || []), ...(selectedRowKeys || [])]?.includes(record.id)}
          />
        </Tooltip>
      );
    };
    rowSelection.getCheckboxProps = (record) => {
      if (selectedRows?.length) {
        return {
          disabled: selectedRows?.[0]?.categoryID !== record?.categoryID,
        };
      }
    };
  }

  // tính tổng tồn kho bé nhất
  const quantitiesArray = selectedRows?.map((product) => {
    const totalQuantity = product?.stocks?.reduce((acc, stock) => acc + stock?.quantity, 0);
    return totalQuantity;
  });
  const flattenedArray = flatten(quantitiesArray);
  const minValue = min(flattenedArray);

  // handle
  const handleChange = (e) => {
    setIsFilterSelected(e.target.value);
  };

  let dataDuplicate = [];

  const copyPromotionTierGroupsDiscountMoney = (allProducts) => {
    return (formValues?.promotionCampaignTierGroupsDiscountMoney || []).map((itemTierGroups, indexTierGroups) => {
      if (indexComboDiscountMoney !== indexTierGroups) {
        return itemTierGroups;
      }

      const newTierList = (itemTierGroups?.promotionCampaignTiers || []).map((itemTiers, indexTiers) => {
        if (indexTiers !== indexPromotionCampaignTiers) {
          return itemTiers;
        }

        const newGroupList = (itemTiers?.conditionProductGroups || []).map((itemGroup, indexGroup) => {
          if (indexGroup !== indexConditionProductGroups) {
            return itemGroup;
          }

          return {
            quantity: itemGroup?.quantity,
            conditionPromotionProducts: allProducts,
            smallestValueInStock: minValue,
          };
        });

        return { ...itemTiers, conditionProductGroups: newGroupList };
      });

      return { ...itemTierGroups, promotionCampaignTiers: newTierList };
    });
  };

  const copyPromotionTierGroupsFixedPrice = (allProducts) => {
    return (formValues?.promotionCampaignTierGroupsFixedPrice || []).map((itemTierGroups, indexTierGroups) => {
      if (indexComboFixedPrice !== indexTierGroups) {
        return itemTierGroups;
      }
      const newTierList = (itemTierGroups?.promotionCampaignTiers || []).map((itemTiers, indexTiers) => {
        if (indexTiers !== indexPromotionCampaignTiers) {
          return itemTiers;
        }
        const newGroupList = (itemTiers?.conditionProductGroups || []).map((itemGroup, indexGroup) => {
          return {
            ...itemGroup,
            conditionPromotionProducts: allProducts,
            smallestValueInStock: minValue,
          };
        });
        return {
          ...itemTiers,
          conditionProductGroups: newGroupList,
        };
      });
      return { ...itemTierGroups, promotionCampaignTiers: newTierList };
    });
  };

  const handleSelectProduct = async () => {
    try {
      const startDate = startOfMomentToISOString(formValues?.period?.[0]);
      const endDate = endOfMomentToISOString(formValues?.period?.at(1));

      const paramsCheckDuplicateCodes = {
        filters: {
          productIDs: selectedRowKeys,
          isActive: true,
          timeRange: {
            from: startDate,
            to: endDate,
          },
          withoutPromotionIDs: id,
          types: PRODUCT_TYPE.CONDITION_PRODUCT,
          promotionTypes: CAMPAIGN_TYPE.COMBO_PROMO,
        },
      };
      const newSelectedRows = selectedRows?.filter((row) => !dataDuplicate?.includes(row?.code));

      const { data } = await handleCheckDuplicateCodes(paramsCheckDuplicateCodes);

      const dataCheckDuplicateCodes = data?.promotionCampaign?.getConditionPromotionProducts;
      dataDuplicate = dataCheckDuplicateCodes?.map((item) => item.product.code) || [];

      form.setFieldsValue({
        ...formValues,
        promotionCampaignTierGroupsDiscountMoney: copyPromotionTierGroupsDiscountMoney(newSelectedRows),
        promotionCampaignTierGroupsFixedPrice: copyPromotionTierGroupsFixedPrice(newSelectedRows),
      });

      setDataDuplicateCode([]);

      if (!startDate && !endDate) {
        notify.error({ message: t("campaign.combo.plsSelectDate") });
      } else {
        if (selectedRowKeys?.length) {
          if (dataCheckDuplicateCodes?.length) {
            setDataDuplicateCode(uniq(dataDuplicate));
            notify.error({
              message: t("campaign.combo.thereIsDuplicateProduct"),
            });
          } else {
            setSelectedProducts(newSelectedRows);
            setIsModalSelectProduct(false);
          }
        } else {
          notify.error({
            message: t("campaign.combo.youHaveNotAnyProduct"),
          });
        }
      }
    } catch (error) {
      console.error("Error handling select product:", error);
    }
  };

  const handleSearchTreeSelect = (search, option) => {
    return option?.label?.toLowerCase()?.indexOf(search?.toLowerCase()) >= 0;
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    };
    setParams(newParams);
  };
  const openModalSelectProduct = () => {
    setIsModalSelectProduct(true);
  };
  const closeModalSelectProduct = () => {
    setIsModalSelectProduct(false);
  };
  const onTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;

    let newParams = {
      ...params,
      pagination: { ...params.pagination, offset: (current - 1) * pageSize },
    };
    setParams(newParams);
  };
  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
    };
    setParams(newParams);
  };

  // renders
  const renderProductAttributes = (attributes = []) => {
    const findDefaultValue = (attributes, value) => {
      if (attributes && value) {
        return attributes.find((item) => item.attributeID === value);
      } else return undefined;
    };
    if (attributes?.length > 0)
      return attributes?.map((attribute) => ({
        title: <div style={{ textTransform: " uppercase" }}>{attribute.name}</div>,
        key: attribute.id,
        width: 125,
        align: "center",
        editable: "false",
        filterDropdown: (
          <FilterAttributes
            attributeId={attribute.id}
            attributeList={attribute.values}
            defaultValue={findDefaultValue(params.filters.attributeValues, attribute.id)?.valueIDs}
            onFilter={filterProductAttributes}
          />
        ),
        render: (_, record) => {
          const prodAtt = record?.fullAttributeValues?.find((item) => item.attributeID === attribute.id);
          if (prodAtt) {
            return (
              <span>{prodAtt?.attributeValues?.map((attributeValue) => attributeValue.value).join(", ") || "- -"}</span>
            );
          }
          return "- -";
        },
      }));
    return [];
  };
  const renderChildrenInStock = (warehouses) => {
    const findDefaultValue = (realStocks, value) => {
      if (realStocks && value) {
        return realStocks.find((item) => item.warehouseID === value);
      } else return undefined;
    };
    return warehouses?.map((warehouse, index) => ({
      title: (
        <div className="title-table" style={{ textTransform: "uppercase" }}>
          {warehouse.name}
        </div>
      ),
      key: index,
      width: 100,
      align: "center",
      editable: "false",
      render: (_, record) =>
        record?.stocks?.filter((stock) => stock?.warehouse?.id === warehouse?.id)[0]?.quantity || "- -",
      filterDropdown: (
        <FilterRealStocks
          onFilter={filterRealStocks}
          warehouseID={warehouse.id}
          defaultValue={findDefaultValue(params.filters.realStocks, warehouse.id)}
        />
      ),
    }));
  };

  // filters
  function filterProductExtra(values) {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...values,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    };

    setParams(newParams);
  }
  function filterProductPrices(values, key) {
    const newValue = values?.isSpecified === undefined ? null : values;

    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        [`${key}`]: newValue,
      },
    };

    setParams(newParams);
  }
  const [isVisibleQuestionModal, setIsVisibleQuestionModal] = useState(false);
  const [newId, setNewIds] = useState([]);

  const handleCancelModalQuestion = () => {
    setIsVisibleQuestionModal(false);
  };
  const handleSubmitQuestion = () => {
    if (isEqual(newId, params.filters.categoryIDs)) {
      return;
    }
    let newParams = {
      ...params,
      filters: {
        ...params.filters,
        categoryIDs: newId?.length > 0 ? newId : undefined,
      },
    };
    setIsVisibleQuestionModal(false);

    setParams(newParams);
  };

  const filterProductByCategoryId = (ids) => {
    if (selectedRows?.length) {
      setIsVisibleQuestionModal(true);
      setNewIds(ids);
    } else {
      if (isEqual(ids, params.filters.categoryIDs)) {
        return;
      }
      let newParams = {
        ...params,
        filters: {
          ...params.filters,
          categoryIDs: ids?.length && ids,
        },
      };
      setParams(newParams);
    }
  };
  function filterRealStocks(values) {
    const realStocks = [
      ...new Map(
        [...(params.filters?.realStocks ? params.filters.realStocks : []), values].map((item) => [
          item.warehouseID,
          item,
        ])
      ).values(),
      // eslint-disable-next-line no-prototype-builtins
    ].filter((item) => item?.hasOwnProperty("range") && item.range);

    setParams({
      ...params,
      filters: {
        ...params.filters,
        realStocks: realStocks.length > 0 ? realStocks : undefined,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    });
  }
  const filterProductAttributes = (values) => {
    const attributeValues = [
      ...new Map(
        [...(params.filters?.attributeValues ? params.filters.attributeValues : []), values].map((item) => [
          item.attributeID,
          item,
        ])
      ).values(),
    ].filter((item) => item.valueIDs?.length > 0);

    setParams({
      ...params,
      filters: {
        ...params.filters,
        attributeValues: attributeValues.length > 0 ? attributeValues : undefined,
      },
      pagination: {
        ...params.pagination,
        offset: 0,
      },
    });
  };

  // useEffects
  useEffect(() => {
    let newParams = {
      ...params,
      filters: {
        ...params.filters,
        query: debouncedValue,
        ids: debouncedValue || isFilterSelected === false ? null : dataProductsKeyServer,
      },
    };
    setParams(newParams);
  }, [debouncedValue]);

  useEffect(() => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setIsFilterSelected(false);
    let newParams = {
      ...params,
      filters: {
        ...params.filters,
        ids: null,
      },
    };
    setParams(newParams);
  }, [JSON.stringify(hasCategory)]);

  useEffect(() => {
    setDataDuplicateCode(dataDuplicate && []);
  }, [isModalSelectProduct]);

  useEffect(() => {
    if (isUpdate) {
      setSelectedRows(dataProductsServer);
      setSelectedProducts(dataProductsServer);
      setSelectedRowKeys(dataProductsKeyServer);
      setIsFilterSelected(true);
      let newParams = {
        ...params,
        filters: {
          ...params.filters,
          ids: dataProductsKeyServer,
        },
      };
      setParams(newParams);
    }
  }, []);

  useEffect(() => {
    let newParams = {
      ...params,
      filters: {
        ...params.filters,
        ids: isFilterSelected === false ? null : dataProductsKeyServer,
      },
    };
    setParams(newParams);
  }, [isFilterSelected]);

  useEffect(() => {
    refetch();
  }, [JSON.stringify(params)]);

  return {
    isModalSelectProduct,
    loadingCheckDuplicateCodes,
    dataAllCategories,
    filterItemsTypeOptions,
    rowSelection,
    loadingProduct,
    isFilterSelected,
    selectedRows,
    productData,
    paginationData,
    dataDuplicateCode,
    prodAtt,
    warehouses,
    openModalSelectProduct,
    closeModalSelectProduct,
    handleSelectProduct,
    filterProductByCategoryId,
    handleSearchTreeSelect,
    handleSearch,
    handleChange,
    onTableChange,
    getParams,
    renderProductAttributes,
    renderChildrenInStock,
    handleFilter,
    params,
    dataManufactures,
    loadingManufactures,
    listWarehouse,
    loadingWarehouses,
    setParams,
    filterProductExtra,
    filterProductPrices,
    hasCategory,
    isVisibleQuestionModal,
    handleCancelModalQuestion,
    handleSubmitQuestion,
    selectedProducts,
  };
}

export default useModalSelectProduct;
