/* eslint-disable no-useless-escape */

import { PERMISSION_ARRAY } from "./permissions";

export const REGEX = {
  NUMBER: new RegExp("^[0-9]+$"),
  PHONE: /([\+84|84|0]+(2|3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/,
  CURRENCY: /\B(?=(\d{3})+(?!\d))/g,
  CURRENCY_PARSER: /\$\s?|(\.*)/g,
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  //At least one upper case, one lower case English letter, one digit, one special character and minimum eight in length
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  CODE: /^[a-zA-Z0-9.-]+$/,
  ONLY_NUMBER: /^\d+$/,
  TEXT_TRIM_SPACE: new RegExp(/^\S.*\S$/),
  NUMBER_WITH_HYPHEN: new RegExp(/^[0-9\-]+$/),
  PHONE_NUMBER: new RegExp(/^(?:^0[235789][0-9]{8,9}|^(84)[235789][0-9]{8,9}|^(\+84)[235789][0-9]{8,9})$/),
  CURRENCY_DEBT: /^\d{1,11}$/,
};

export const INPUT_TYPE = {
  TEXT_AREA: "TEXT_AREA",
  NUMBER: "NUMBER",
  SELECT: "SELECT",
  INPUT: "INPUT",
  MASKED_INPUT: "MASKED_INPUT",
  DATE_PICKER: "DATE_PICKER",
  PASSWORD: "PASSWORD",
  IMAGE_UPLOAD: "IMAGE_UPLOAD",
  RANGE_PICKER: "RANGE_PICKER",
  BUTTON_GROUP: "BUTTON_GROUP",
  PHONE_NUMBER_INPUT: "PHONE_NUMBER_INPUT",
  CHECK_BOX: "CHECK_BOX",
  RADIO_GROUP: "RADIO_GROUP",
  TIME_PICKER: "TIME_PICKER",
  SWITCH: "SWITCH",
  CURRENCY_INPUT: "CURRENCY_INPUT",
  TREE_SELECT: "TREE_SELECT",
  SELECT_LOAD_MORE: "SELECT_LOAD_MORE",
  UPLOAD_PREVIEW: "UPLOAD_PREVIEW",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  CHECK_BOX_GROUP: "CHECK_BOX_GROUP",
  AUTO_COMPLETE: "AUTO_COMPLETE",
  ONLY_NUMBER: "ONLY_NUMBER",
};

export const TOKEN = "token";

export const FORM_LAYOUT = {
  layout: "horizontal",
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const FORM_TYPE = {
  CREATE: "CREATE",
  DETAIL: "DETAIL",
};

export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";

export const DATE_TIME_FORMAT2 = "DD/MM/YYYY HH:mm:ss";

export const DATE_TIME_FORMAT3 = "DD/MM/YYYY - HH:mm";

export const DATE_TIME_FORMAT4 = "HH:mm:ss DD/MM/YYYY";

export const DATE_TIME_FORMAT5 = "MM/DD/YYYY HH:mm:ss";

export const DATE_FORMAT = "DD/MM/YYYY";

export const DATE_FORMAT_2 = "YYYY-MM-DD";

export const DATE_MONTH_FORMAT = "DD/MM";

export const DATE_ISO_8601_FORMAT = "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"; //cause timezone bug

export const TIME_FORMAT = "HH:mm:ss";

export const ACCEPT_IMG_TYPES = ["image/png", "image/jpg", "image/jpeg"];

export const ACCEPT_FILE_TYPES = ["image/*", ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".csv"];

export const PRODUCT_INPUT_TYPE = {
  SHORT_CODE: "shortCode",
  CURRENCY: "currency",
  NUMBER: "number",
  VAT: "vat",
  VAT_PERCENT: "vatPercent",
  VAT_VALUE: "vatValue",
  NOTE: "note",
};

export const USER_PERMISSIONS = PERMISSION_ARRAY.reduce(
  (previousValue, currentValue) => ({ ...previousValue, [currentValue]: currentValue }),
  {}
);

export const SHIPPING_TYPE = {
  BUS: "BUS",
  URBAN_COD: "URBAN_COD",
  DELIVER_3PARTY: "DELIVER_3PARTY",
  PICK_UP_COUNTER: "PICK_UP_COUNTER",
  URBAN_NOT_COD: "URBAN_NOT_COD",
};

export const PRIORITY_LEVEL = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
};

export const PAYMENT_METHOD = {
  Cash: "CASH",
  CashAndDebt: "CASH_AND_DEBT",
  COD: "COD",
  Debt: "DEBT",
  WALLET: "WALLET",
};

export const STATUS_ORDER = {
  ALL: "ALL",
  WAITING_FOR_APPROVAL_ORDER: "WAITING_FOR_APPROVAL_ORDER",
  WAITING_FOR_APPROVAL_STOCK: "WAITING_FOR_APPROVAL_STOCK",
  WAITING_FOR_ENOUGH_STOCK: "WAITING_FOR_ENOUGH_STOCK",
  WAITING_FOR_PAYMENT: "WAITING_FOR_PAYMENT",
  WAITING_FOR_PACKAGING: "WAITING_FOR_PACKAGING",
  WAITING_FOR_EXPORTING: "WAITING_FOR_EXPORTING",
  WAITING_FOR_ASSEMBLY: "WAITING_FOR_ASSEMBLY",
  WAITING_FOR_SHIPPING: "WAITING_FOR_SHIPPING",
  WAITING_FOR_SHIPPING_NOT_SET: "WAITING_FOR_SHIPPING_NOT_SET",
  WAITING_FOR_SHIPPING_WAITING: "WAITING_FOR_SHIPPING_WAITING",
  DELIVERING: "DELIVERING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const FULL_STOCK_REQUIRED = {
  YES: true,
  NO: false,
};

export const STOCK_STATUS = {
  FULL: "FULL",
  LACK: "LACK",
  OUT_OF_STOCK: "OUT_OF_STOCK",
};

export const EXPORT_VAT = {
  YES: true,
  NO: false,
};

export const EXPORT_CART_TYPE = {
  JPEG: "JPEG",
  PDF: "PDF",
};

export const EXPORT_FILE_ACTION = {
  CART: "CART",
  ORDER: "ORDER",
};

export const ACTION_FORM_TYPE = {
  CREATE: "CREATE",
  EDIT: "EDIT",
};

export const PRODUCT_TYPE = {
  MATERIAL: "MATERIAL",
  SERVICE: "SERVICE",
  KEY_LICENSE: "KEY_LICENSE",
  ACCOUNT: "ACCOUNT",
};

export const NOTIFICATION_STATUS = {
  READ: "READ",
  UNREAD: "UNREAD",
};

export const EVENT_STATUS = {
  FUTURE: "FUTURE",
  CURRENT: "CURRENT",
  PAST: "PAST",
};

export const ANTD_STEPS_STATUS = {
  WAIT: "wait",
  PROCESS: "process",
  FINISH: "finish",
  ERROR: "error",
};

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED",
  DELETED: "DELETED",
};

export const DIMENSION_UNIT = {
  MILLIMETER: "MILLIMETRE",
  CENTIMETER: "CENTIMETRE",
  METER: "METRE",
};

export const WEIGHT_UNIT = {
  GRAM: "GRAM",
  KILOGRAM: "KILOGRAM",
  QUINTAL: "QUINTAL",
  TON: "TON",
};

export const GREETING = {
  GOOD_MORNING: "GOOD_MORNING",
  GOOD_AFTERNOON: "GOOD_AFTERNOON",
  GOOD_EVENING: "GOOD_EVENING",
  GOOD_NIGHT: "GOOD_NIGHT",
  HELLO: "HELLO",
};

export const STATUS_SERIAL = {
  ALL: "ALL",
  WAITING_FOR_CHECK: "WAITING_FOR_CHECK",
  CHECKING: "CHECKING",
  WAITING_FOR_DISTRIBUTE: "WAITING_FOR_DISTRIBUTE",
  REPAIRING: "REPAIRING",
  WAITING_FOR_RETURN: "WAITING_FOR_RETURN",
  DELIVERING: "DELIVERING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const SERIAL_AVAILABILITY = {
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE",
};

export const PLATFORMS_CODE = {
  HA: "HA",
  CG: "CG",
};

export const PRICE_POLICY_TYPE = {
  QUANTITY_DISCOUNT: "QUANTITY_DISCOUNT",
  NO_QUANTITY_DISCOUNT: "NO_QUANTITY_DISCOUNT",
};
