import { Descriptions, Space, Tag, Typography, message } from "antd";
import copy from "copy-to-clipboard";
import { useGenerateLink } from "hooks/generateLink/query";
import { t } from "i18next";
import { formatCurrency, formatDatetime } from "utils/helperFuncs";

import CustomDescriptions from "components/CustomDescriptions";

const InfoOverView = ({ data }) => {
  const countCampaignProductPromo = data?.promotionCampaignTierGroups?.[0]?.promotionCampaignTiers?.length ?? 0;

  const { handleGenerateLink } = useGenerateLink();

  const handleCopyName = async () => {
    let itemsCopy = data?.promotionCampaignTierGroups?.flatMap((item, index) =>
      item?.promotionCampaignTiers?.flatMap((group) => {
        return group.conditionProductGroups.flatMap((productGroup) =>
          productGroup.conditionPromotionProducts.map((promotionProduct) => promotionProduct.product.name)
        );
      })
    );

    try {
      const shopURL = process.env.REACT_APP_SHOP;
      const link = `${shopURL}/promotion_detail?promotion_id=${data?.id}`;
      await handleGenerateLink({ url: link }).then((res) => {
        const dynamicLayout = `${data?.name}
Xem tại: ${res?.data?.linkQuery?.generateDynamicLinkFromUrl}
${itemsCopy?.map((item) => `- ${item}`).join("\n")}`;

        copy(dynamicLayout);

        message.success("Đã sao chép link vào bộ nhớ tạm");
      });
    } catch (error) {
      message.success("Sao chép không thành công");
      console.info(error);
    }
  };

  return (
    <CustomDescriptions
      labelWidth="20%"
      bordered={false}
      colon={false}
      content={
        <>
          <Descriptions.Item label={t("campaign.form.campaignName")}>
            <Typography.Text
              copyable={["ON_GOING", "UPCOMING"].includes(data?.status) ? { onCopy: handleCopyName } : false}
            >
              {data?.name || "--"}
            </Typography.Text>
          </Descriptions.Item>
          <br />
          <Descriptions.Item label={t("campaign.form.campaignPeriod")}>
            <Space direction="vertical">
              <Typography.Text>
                {t("product.inputRange.from")}: {data?.startDate ? formatDatetime(data?.startDate) : "--"}
              </Typography.Text>
              <Typography.Text>
                {t("campaign.detailPromo.to")}: {data?.endDate ? formatDatetime(data?.endDate) : "--"}
              </Typography.Text>
            </Space>
          </Descriptions.Item>
          <br />
          <Descriptions.Item label={t("campaign.tags.channelBuy")}>
            <Space direction="vertical">
              {data?.saleChannels?.map((item, index) => (
                <Tag color="blue" key={index}>
                  {item?.name}
                </Tag>
              ))}
            </Space>
          </Descriptions.Item>
          {data?.campaignType === "PRODUCT_PROMO" ? (
            <>
              <br />
              <Descriptions.Item label={t("campaign.detailPromo.sumProductPromo")}>
                <Typography.Text>
                  {countCampaignProductPromo > 2 ? countCampaignProductPromo : `0${countCampaignProductPromo}`}
                </Typography.Text>
              </Descriptions.Item>
            </>
          ) : (
            <>
              <br />
              <Descriptions.Item label={t("campaign.detailPromo.sumBudget")}>
                <Space direction="vertical">
                  {data?.totalBudget ? (
                    <Typography.Text>
                      {t("campaign.combo.totalPromotionAmount")}: {formatCurrency(data?.totalBudget)}
                    </Typography.Text>
                  ) : null}
                  {data?.totalGift ? (
                    <Typography.Text>
                      {t("campaign.detailPromo.totalAmountToGifts")}: {data?.totalGift}
                    </Typography.Text>
                  ) : null}
                  {data?.totalFixedPriceProduct ? (
                    <Typography.Text>
                      {t("campaign.detailPromo.totalPriceFix")}: {data?.totalFixedPriceProduct}
                    </Typography.Text>
                  ) : null}
                </Space>
              </Descriptions.Item>
            </>
          )}
        </>
      }
    />
  );
};

export default InfoOverView;
