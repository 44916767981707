import { DATE_FORMAT } from "config/constants";
import moment from "moment";
import { useState } from "react";
import {
  endOfDayMomentToISOString,
  formatDateTime,
  parseMomentDate,
  startOfDayMomentToISOString,
} from "utils/dateTime";
import "./index.scss";

function useFilterDate({ onChangeDate }) {
  const [isOpen, setIsOpen] = useState(false);
  const today = moment();
  const [value, setValue] = useState([]);

  const thisDay = [parseMomentDate(today.startOf("day")), parseMomentDate(today.endOf("day"))];

  const yesterday = [parseMomentDate(today.add(-1, "days").startOf("day")), parseMomentDate(today).endOf("day")];

  const thisWeek = [
    parseMomentDate(today.startOf("week").startOf("day")),
    parseMomentDate(today.endOf("week").endOf("day")),
  ];

  const lastWeek = [
    parseMomentDate(today.subtract(1, "weeks").startOf("weeks").startOf("day")),
    parseMomentDate(today.endOf("weeks")).endOf("day"),
  ];

  const thisMonth = [
    parseMomentDate(today.startOf("month").startOf("day")),
    parseMomentDate(today.endOf("month").endOf("day")),
  ];

  const lastMonth = [
    parseMomentDate(today.subtract(1, "months").startOf("month").startOf("day")),
    parseMomentDate(today.endOf("month").endOf("day")),
  ];

  const renderRangerPicker = () => {
    return (
      <>
        {value.length !== 0
          ? value[0].diff(value[1])
            ? `${formatDateTime(value[0], DATE_FORMAT)} - ${formatDateTime(value[1], DATE_FORMAT)}`
            : `${formatDateTime(value[0], DATE_FORMAT)}`
          : ""}
      </>
    );
  };

  const onCancel = () => setValue([]);

  const onConfirm = () => {
    onChangeDate &&
      onChangeDate({
        from: startOfDayMomentToISOString(value[0]),
        to: endOfDayMomentToISOString(value[1]),
      });
  };

  const onChange = (value) => {
    setValue(value);
  };

  const onOpen = () => setIsOpen(!isOpen);

  const onOpenChange = (isOpen) => setIsOpen(!isOpen);

  const onClickMenu = (value) => setValue(value);

  return {
    thisDay,
    yesterday,
    thisWeek,
    lastWeek,
    thisMonth,
    lastMonth,
    value,
    isOpen,
    renderRangerPicker,
    onCancel,
    onConfirm,
    onChange,
    onOpen,
    onOpenChange,
    onClickMenu,
  };
}

export default useFilterDate;
