/* eslint-disable react-hooks/exhaustive-deps */
import { Input, Select } from "antd";
import { FontAwesomeIcon, Icon } from "assets/icons";
import CustomTable from "components/CustomTable";
import { PLATFORMS_CODE, STATUS_ORDER } from "config/constants";
import { SORT_TYPES, convertOrderSortBy } from "features/Campaign/constants";
import { useGetBranchQuery } from "hooks/branch";
import { useGetInfoPlatform } from "hooks/info-platform";
import { useGetCreatedByWithIds, useGetOrders, useGetWarehousesWithIds } from "hooks/order";
import { useSalesChannelWithIds } from "hooks/salesChannel";
import useDebounce from "hooks/useDebounce";
import { useGetUserBranch } from "hooks/user/user";
import { unset } from "lodash";
import { useOrder } from "pages/App/Order/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { buildQueryString, getQuery } from "utils/helperFuncs";
import useColumnsOrder, { orderTypeOption } from "../ColumnsOrder";
import FilterMenu from "../FilterMenu";
import TagsList from "../TagsList";
import WaitingApprovalButtons from "../WaitingApprovalButtons";
import WaitingAssemblyButtons from "../WaitingAssemblyButtons";
import WaitingExportingButtons from "../WaitingExportingButtons";
import WaitingForApprovalOrderButtons from "../WaitingForApprovalOrderButtons";
import WaitingPackagingButtons from "../WaitingPackagingButtons";
import WaitingPaymentButtons from "../WaitingPaymentButtons";
import WaitingStocksButtons from "../WaitingStocksButtons";
import "./index.scss";

const OrderTable = () => {
  const GET_QUERY = getQuery(false);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();
  const { orderPriorityOptions, shippingOptions, paymentMethodOptions, statusOptions, paymentStatusOptions } =
    useOrder();
  const { data: valueFormSelectCity } = useGetBranchQuery();
  const { branchIdByUser } = useGetUserBranch();
  valueFormSelectCity?.unshift({ value: "ALL", label: t("warehouse.recommendImport.allBranch") });
  const [searchTerm, setSearchTerm] = useState();
  const [checkedList, setCheckedList] = useState([]);
  const [checkedKeyList, setCheckedKeyList] = useState([]);
  const debouncedValue = useDebounce(searchTerm);
  const [params, setParams] = useState({
    activeKey: GET_QUERY.activeKey || "ALL",
    filters: {
      preOrder: GET_QUERY.preOrder || undefined,
      statuses:
        GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET
          ? STATUS_ORDER.WAITING_FOR_SHIPPING
          : GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_SHIPPING_WAITING
          ? STATUS_ORDER.WAITING_FOR_SHIPPING
          : GET_QUERY?.statuses,
      shippingStatus:
        GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET
          ? "NOT_SET"
          : GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_SHIPPING_WAITING
          ? "WAITING"
          : undefined,
      query: GET_QUERY.query || undefined,
      exportedWarehouseIDs: GET_QUERY.exportedWarehouseIDs || [],
      priorities: GET_QUERY.priorities || [],
      campaignTypes: GET_QUERY.campaignTypes || [],
      fullStockRequired: GET_QUERY.fullStockRequired || undefined,
      shippingTypes: GET_QUERY.shippingTypes || [],
      vat: GET_QUERY.vat || undefined,
      paymentMethods: GET_QUERY.paymentMethods || [],
      paymentStatuses: GET_QUERY.paymentStatuses || [],
      saleChannelIDs: GET_QUERY.saleChannelIDs || [],
      createdByIDs: GET_QUERY.createdByIDs || [],
      stockStatus: GET_QUERY.stockStatus || undefined,
      timeRange: {
        from: GET_QUERY?.from || undefined,
        to: GET_QUERY?.to || undefined,
      },
      branchID: GET_QUERY?.branchID || undefined,
      cancelReasons: {
        staff: GET_QUERY.staffCancelReason || undefined,
        seller: GET_QUERY.sellerCancelReason || undefined,
      },
    },
    pagination: { offset: GET_QUERY.offset || 0, limit: 10 },
    sort: [],
  });
  const { data, loading, paginationData, refetchOrders } = useGetOrders(params);

  const { data: dataWarehousesWithIds, loading: loadingWarehousesWithIds } = useGetWarehousesWithIds({
    request: {
      ids: GET_QUERY.exportedWarehouseIDs ? GET_QUERY.exportedWarehouseIDs : undefined,
    },
  });
  const { data: dataCreatedByWithIds, loading: loadingCreatedByWithIds } = useGetCreatedByWithIds({
    ids: GET_QUERY.createdByIDs ? GET_QUERY.createdByIDs : undefined,
  });
  const [filterTags, setFilterTags] = useState({});

  const findFilterTags = (keyQuery, options = []) => {
    if (GET_QUERY && Array.isArray(GET_QUERY[keyQuery])) {
      return GET_QUERY[keyQuery].map((itemQuery) => options.find((itemPriority) => itemPriority.value === itemQuery));
    } else {
      return [];
    }
  };

  const { platformCode } = useGetInfoPlatform();

  const { TABLE_BY_TAB } = useColumnsOrder(params, setParams, filterTags, setFilterTags);
  const { convertedData: dataSalesChannel, loading: loadingSalesChannelWithIds } = useSalesChannelWithIds({
    ids: GET_QUERY.saleChannelIDs ? GET_QUERY.saleChannelIDs : undefined,
  });

  const rowSelection = () => {
    switch (GET_QUERY.activeKey) {
      case STATUS_ORDER.ALL:
      case STATUS_ORDER.DELIVERING:
      case STATUS_ORDER.COMPLETED:
        return undefined;
      case STATUS_ORDER.WAITING_FOR_APPROVAL_ORDER:
      case STATUS_ORDER.WAITING_FOR_APPROVAL_STOCK:
      case STATUS_ORDER.WAITING_FOR_ENOUGH_STOCK:
      case STATUS_ORDER.WAITING_FOR_PAYMENT:
        return {
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            setCheckedKeyList(selectedRowKeys);
            setCheckedList(selectedRows);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: checkedKeyList,

          hideSelectAll:
            GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_PAYMENT ||
            GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_APPROVAL_ORDER,
          getCheckboxProps: (record) => {
            const condition = (status) => checkedList?.length >= 1 && record?.status === status;
            if (condition(STATUS_ORDER.WAITING_FOR_PAYMENT)) {
              return {
                disabled: checkedList[0]?.number !== record?.number,
              };
            }
            if (condition(STATUS_ORDER.WAITING_FOR_APPROVAL_ORDER)) {
              return {
                disabled: checkedList[0]?.id !== record?.id,
              };
            }
          },
        };
      case STATUS_ORDER.WAITING_FOR_EXPORTING:
        return {
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            setCheckedKeyList(selectedRowKeys);
            setCheckedList(selectedRows);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: checkedKeyList,

          hideSelectAll: GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_EXPORTING && true,
          getCheckboxProps: (record) => {
            const condition = (status) => checkedList?.length >= 1 && record?.status === status;
            if (condition(STATUS_ORDER.WAITING_FOR_EXPORTING)) {
              return {
                disabled: checkedList[0]?.number !== record?.number,
              };
            }
          },
        };
      case STATUS_ORDER.WAITING_FOR_ASSEMBLY:
      case STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET:
        return {
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            setCheckedKeyList(selectedRowKeys);
            setCheckedList(selectedRows);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: checkedKeyList,

          hideSelectAll: GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET && true,
          getCheckboxProps: (record) => {
            const condition = (status) => checkedList?.length >= 1 && record?.status === status;
            if (condition(STATUS_ORDER.WAITING_FOR_SHIPPING)) {
              return {
                disabled: checkedList[0]?.number !== record?.number,
              };
            }
          },
        };
      case STATUS_ORDER.WAITING_FOR_SHIPPING_WAITING:
        return {
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            setCheckedKeyList(selectedRowKeys);
            setCheckedList(selectedRows);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: checkedKeyList,

          hideSelectAll: GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET && true,
          getCheckboxProps: (record) => {
            const condition = (status) => checkedList?.length >= 1 && record?.status === status;
            if (condition(STATUS_ORDER.WAITING_FOR_SHIPPING)) {
              return {
                disabled: checkedList[0]?.number !== record?.number,
              };
            }
          },
        };
      // case STATUS_ORDER.CANCELED:
      case STATUS_ORDER.WAITING_FOR_PACKAGING:
        return {
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            setCheckedKeyList(selectedRowKeys);
            setCheckedList(selectedRows);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: checkedKeyList,

          hideSelectAll:
            GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_EXPORTING && platformCode === PLATFORMS_CODE.CG && true,
          getCheckboxProps: (record) => {
            const condition = (status) =>
              checkedList?.length >= 1 && record?.status === status && platformCode === PLATFORMS_CODE.CG;
            if (condition(STATUS_ORDER.WAITING_FOR_EXPORTING)) {
              return {
                disabled: checkedList[0]?.number !== record?.number,
              };
            }
          },
        };
      default:
        break;
    }
  };

  const resetCheckedList = () => {
    setCheckedKeyList([]);
    setCheckedList([]);
  };

  const renderButtonGroup = () => {
    switch (GET_QUERY.activeKey) {
      case STATUS_ORDER.WAITING_FOR_APPROVAL_ORDER:
        return (
          <WaitingForApprovalOrderButtons
            checkedKeyList={checkedKeyList}
            selectedList={checkedList}
            resetCheckedList={resetCheckedList}
          />
        );
      case STATUS_ORDER.WAITING_FOR_APPROVAL_STOCK:
        return (
          <WaitingApprovalButtons
            checkedKeyList={checkedKeyList}
            selectedList={checkedList}
            resetCheckedList={resetCheckedList}
          />
        );
      case STATUS_ORDER.WAITING_FOR_ENOUGH_STOCK:
        return (
          <WaitingStocksButtons
            checkedKeyList={checkedKeyList}
            selectedList={checkedList}
            resetCheckedList={resetCheckedList}
          />
        );

      case STATUS_ORDER.WAITING_FOR_PAYMENT:
        return (
          <WaitingPaymentButtons
            checkedKeyList={checkedKeyList}
            resetCheckedList={resetCheckedList}
            checkedList={checkedList}
          />
        );

      case STATUS_ORDER.WAITING_FOR_PACKAGING:
        return <WaitingPackagingButtons checkedKeyList={checkedKeyList} resetCheckedList={resetCheckedList} />;

      case STATUS_ORDER.WAITING_FOR_EXPORTING:
        return (
          <WaitingExportingButtons
            checkedKeyList={checkedKeyList}
            resetCheckedList={resetCheckedList}
            checkedList={checkedList}
          />
        );

      case STATUS_ORDER.WAITING_FOR_ASSEMBLY:
        return <WaitingAssemblyButtons checkedKeyList={checkedKeyList} resetCheckedList={resetCheckedList} />;
      default:
        return;
    }
  };

  function onTableChange(pagination, filters, sorter) {
    const { current, pageSize } = pagination;

    let newParams = {
      ...params,
      activeKey: GET_QUERY?.activeKey,
      pagination: { ...params.pagination, offset: (current - 1) * pageSize },
      sort: sorter?.order
        ? [{ by: convertOrderSortBy[sorter.column.dataIndex], orderType: SORT_TYPES[`${sorter?.order}`] }]
        : [],
    };

    setParams(newParams);
    const queryString = {
      ...newParams.filters,
      ...newParams.pagination,
      ...newParams.sort,
      ...newParams.filters.timeRange,
      activeKey: GET_QUERY?.activeKey,
    };
    unset(queryString, "timeRange");
    buildQueryString({ params: { ...queryString } });
  }

  const onChangeBranchesId = (e) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        branchID: e === "ALL" ? undefined : e,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
        activeKey: GET_QUERY?.activeKey,
      },
    });
  };

  useEffect(() => {
    setFilterTags({
      exportedWarehouseIDs: dataWarehousesWithIds,
      priorities: findFilterTags("priorities", orderPriorityOptions),
      campaignTypes: findFilterTags("campaignTypes", orderTypeOption),
      shippingTypes: findFilterTags("shippingTypes", shippingOptions),
      paymentMethods: findFilterTags("paymentMethods", paymentMethodOptions),
      paymentStatuses: findFilterTags("paymentStatuses", paymentStatusOptions),
      saleChannelIDs: dataSalesChannel,
      statuses: findFilterTags("statuses", statusOptions),
      createdByIDs: dataCreatedByWithIds,
      vat: GET_QUERY?.vat,
      preOrder: GET_QUERY?.preOrder,
      fullStockRequired: GET_QUERY.fullStockRequired,
      stockStatus: GET_QUERY.stockStatus,
      timeRange: {
        from: GET_QUERY?.from,
        to: GET_QUERY?.to,
      },
    });
  }, [loadingWarehousesWithIds, loadingCreatedByWithIds, loadingSalesChannelWithIds, JSON.stringify(GET_QUERY)]);

  useEffect(() => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        query: debouncedValue ? debouncedValue : undefined,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    const queryString = {
      statuses:
        GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_SHIPPING && newParams.filters.shippingStatus === "NOT_SET"
          ? [STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET]
          : GET_QUERY.activeKey === STATUS_ORDER.WAITING_FOR_SHIPPING && newParams.filters.shippingStatus === "WAITING"
          ? [STATUS_ORDER.WAITING_FOR_SHIPPING_WAITING]
          : newParams.filters?.statuses || [],
      shippingStatus: newParams.filters.shippingStatus,
      branchID: newParams.filters.branchID,
      createdByIDs: newParams.filters.createdByIDs,
      exportedWarehouseIDs: newParams.filters.exportedWarehouseIDs,
      fullStockRequired: newParams.filters.fullStockRequired,
      paymentMethods: newParams.filters.paymentMethods,
      paymentStatuses: newParams.filters.paymentStatuses,
      saleChannelIDs: newParams.filters.saleChannelIDs,
      activeKey: GET_QUERY.activeKey,
      campaignTypes: newParams.filters.campaignTypes,
      priorities: newParams.filters.priorities,
      query: newParams.filters.query,
      shippingTypes: newParams.filters.shippingTypes,
      stockStatus: newParams.filters.stockStatus,
      timeRange: newParams.filters.timeRange,
      vat: newParams.filters.vat,
      preOrder: newParams.filters.preOrder,
      staffCancelReasons: newParams?.filters?.cancelReasons?.staff,
      sellerCancelReasons: newParams?.filters?.cancelReasons?.seller,
      ...newParams.pagination,
      ...newParams.sort,
      ...newParams.filters.timeRange,
    };
    unset(queryString, "timeRange");
    buildQueryString({ params: { ...queryString } });
  }, [debouncedValue]);

  const convertShippingStatus = (activeKey) => {
    switch (activeKey) {
      case STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET:
        return "NOT_SET";
      case STATUS_ORDER.WAITING_FOR_SHIPPING_WAITING:
        return "WAITING";
      default:
        return undefined;
    }
  };

  const convertStatuses = (activeKey) => {
    switch (activeKey) {
      case STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET:
      case STATUS_ORDER.WAITING_FOR_SHIPPING_WAITING:
        return ["WAITING_FOR_SHIPPING"];
      default:
        return GET_QUERY.statuses;
    }
  };

  useEffect(() => {
    if (isMounted) {
      const newParams = {
        filters: {
          statuses: convertStatuses(GET_QUERY.activeKey),
          shippingStatus: convertShippingStatus(GET_QUERY.activeKey),
        },
        pagination: {
          offset: GET_QUERY.offset ? GET_QUERY.offset : 0,
          limit: 10,
        },
        sort: [],
      };
      setFilterTags({});
      setParams(newParams);
    } else {
      const newParams = {
        filters: {
          ...params.filters,
          statuses: convertStatuses(GET_QUERY.activeKey),
          shippingStatus: convertShippingStatus(GET_QUERY.activeKey),
        },
        pagination: {
          offset: GET_QUERY.offset ? GET_QUERY.offset : 0,
          limit: 10,
        },
        sort: [],
      };
      setParams(newParams);
      setIsMounted(true);
    }
  }, [GET_QUERY.activeKey]);

  useEffect(() => {
    const newParams = { ...params };
    const queryString = {
      statuses:
        newParams.filters.statuses === STATUS_ORDER.WAITING_FOR_SHIPPING &&
        newParams.filters.shippingStatus === "NOT_SET"
          ? [STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET]
          : newParams.filters.statuses === STATUS_ORDER.WAITING_FOR_SHIPPING &&
            newParams.filters.shippingStatus === "WAITING"
          ? [STATUS_ORDER.WAITING_FOR_SHIPPING_WAITING]
          : newParams.filters?.statuses || [],
      branchID: newParams.filters.branchID,
      createdByIDs: newParams.filters.createdByIDs,
      exportedWarehouseIDs: newParams.filters.exportedWarehouseIDs,
      fullStockRequired: newParams.filters.fullStockRequired,
      paymentMethods: newParams.filters.paymentMethods,
      paymentStatuses: newParams.filters.paymentStatuses,
      saleChannelIDs: newParams.filters.saleChannelIDs,
      priorities: newParams.filters.priorities,
      campaignTypes: newParams.filters.campaignTypes,
      query: newParams.filters.query,
      shippingTypes: newParams.filters.shippingTypes,
      stockStatus: newParams.filters.stockStatus,
      timeRange: newParams.filters.timeRange,
      vat: newParams.filters.vat,
      preOrder: newParams.filters.preOrder,
      activeKey: GET_QUERY.activeKey,
      staffCancelReasons: newParams?.filters?.cancelReasons?.staff,
      sellerCancelReasons: newParams?.filters?.cancelReasons?.seller,
      ...newParams.pagination,
      ...newParams.sort,
      ...newParams.filters.timeRange,
    };
    unset(queryString, "timeRange");
    buildQueryString({ params: { ...queryString } });
    refetchOrders();
  }, [params]);

  useEffect(() => {
    setCheckedKeyList([]);
    setCheckedList([]);
  }, [GET_QUERY.activeKey]);

  useEffect(() => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        branchID: undefined,
      },
    });
  }, [branchIdByUser]);

  useEffect(() => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        cancelReasons: {
          staff: GET_QUERY.staffCancelReasons,
          seller: GET_QUERY.sellerCancelReasons,
        },
        shippingStatus: convertShippingStatus(GET_QUERY.activeKey),
        statuses: convertStatuses(GET_QUERY.activeKey) || undefined,
        branchID: GET_QUERY.branchID,
      },
    };

    const queryString = {
      ...newParams,
      staffCancelReasons: GET_QUERY.staffCancelReasons,
      sellerCancelReasons: GET_QUERY.sellerCancelReasons,
    };

    setParams(newParams);

    buildQueryString({
      params: {
        ...queryString,
      },
    });

    setSearchTerm(GET_QUERY.query);
  }, []);

  return (
    <div className="order-container">
      <div className="filter-box" style={{ flexWrap: "wrap" }}>
        <div className="search-input">
          <FilterMenu params={params} setParams={setParams} filterTags={filterTags} setFilterTags={setFilterTags} />
          <Input
            className="custom-input"
            prefix={<FontAwesomeIcon icon={Icon.faSearch} />}
            placeholder={t("order.orderList.placeholderInputSearch")}
            allowClear={true}
            defaultValue={params.filters.query}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <div style={{ minWidth: "160px" }}>
            <Select
              options={valueFormSelectCity}
              onChange={(e) => onChangeBranchesId(e)}
              defaultValue={params?.filters?.branchID || "ALL"}
            />
          </div>
          {renderButtonGroup()}
        </div>
      </div>
      <TagsList
        params={params}
        setParams={setParams}
        statusOrder={GET_QUERY.activeKey}
        filterTags={filterTags}
        setFilterTags={setFilterTags}
      />
      <CustomTable
        columns={TABLE_BY_TAB[`${GET_QUERY.activeKey}`]}
        pagination={{
          total: paginationData?.total,
          pageSize: paginationData?.limit,
          current: paginationData?.offset / paginationData?.limit + 1 || 1,
          showSizeChanger: false,
        }}
        dataSource={data}
        scroll={{ x: 1500, y: "calc(80vh - 250px)" }}
        loading={loading}
        onChange={onTableChange}
        rowSelection={rowSelection()}
        rowKey={(obj) => obj?.id}
      />
    </div>
  );
};

export default OrderTable;
