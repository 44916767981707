import { DATE_FORMAT, DATE_ISO_8601_FORMAT, DATE_TIME_FORMAT } from "config/constants";
import moment from "moment";

export function parseMomentDate(dateStr) {
  if (dateStr) return moment(dateStr);
  return null;
}

export const getTimestampByDate = (date) => {
  return moment(date, DATE_TIME_FORMAT).toISOString();
};

export const decimalTimeToTimeString = (decimalTimeString) => {
  let decimalTime = parseFloat(Math.abs(decimalTimeString));
  let day = Math.floor(decimalTime / 24);
  decimalTime = decimalTime * 60 * 60;
  let hours = Math.floor(decimalTime / (60 * 60));
  decimalTime = decimalTime - hours * 60 * 60;
  let minutes = Math.floor(decimalTime / 60);
  decimalTime = decimalTime - minutes * 60;
  let seconds = Math.round(decimalTime);
  if (day >= 1) {
    return `${day} ngày`;
  } else {
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return `${hours}:${minutes}:${seconds}`;
  }
};

export const formatDateTime = (date, format = DATE_TIME_FORMAT, utc = false) => {
  if (!date) {
    return "- -";
  }
  let momentDate = utc ? moment.utc(date) : moment(date);
  if (!momentDate.isValid()) {
    return "- -";
  }

  return momentDate.format(format);
};

export const formatTime = (date, utc = 7) => {
  return moment(date).utcOffset(utc, false).format("HH:mm");
};

//input must be timestamp, output be array of moment obj
export const parseTimestampTimeRange = (dateFrom, dateTo) => {
  if (!!dateFrom && !dateTo) return [moment(dateFrom), undefined];
  if (!dateFrom && !!dateTo) return [undefined, moment(dateTo)];
  if (!!dateFrom && !!dateTo) return [moment(dateFrom), moment(dateTo)];
};

export const startOfDay = (time) => {
  return formatDateTime(time?.startOf("day"), DATE_ISO_8601_FORMAT);
};

export const endOfDay = (time) => {
  return formatDateTime(time?.endOf("day")?.subtract(1, "s"), DATE_ISO_8601_FORMAT);
};

export const timeRanges = () => {
  const currentTime = moment();

  return {
    today: [parseMomentDate(currentTime.startOf("day")), parseMomentDate(currentTime.endOf("day"))],
    yesterday: [parseMomentDate(currentTime.add(-1, "days").startOf("day")), parseMomentDate(currentTime).endOf("day")],
    thisWeek: [
      parseMomentDate(currentTime.startOf("week").startOf("day")),
      parseMomentDate(currentTime.endOf("week").endOf("day")),
    ],
    lastWeek: [
      parseMomentDate(currentTime.subtract(1, "weeks").startOf("weeks").startOf("day")),
      parseMomentDate(currentTime.endOf("weeks")).endOf("day"),
    ],
    thisMonth: [
      parseMomentDate(currentTime.startOf("month").startOf("day")),
      parseMomentDate(currentTime.endOf("month").endOf("day")),
    ],
    lastMonth: [
      parseMomentDate(currentTime.subtract(1, "months").startOf("month").startOf("day")),
      parseMomentDate(currentTime.endOf("month").endOf("day")),
    ],
    lastYear: [
      parseMomentDate(currentTime.subtract(1, "years").startOf("year").startOf("month").startOf("day")),
      parseMomentDate(currentTime.endOf("year").endOf("month").endOf("day")),
    ],
  };
};

export const convertDateRangeMomentToTimestamp = (dateRange) => {
  return dateRange?.length > 0 ? [startOfDay(dateRange.at(0)), endOfDay(dateRange.at(1))] : [];
};

export const startOfDayMomentToISOString = (time) => {
  return time?.startOf("day")?.toISOString(true);
};

export const endOfDayMomentToISOString = (time) => {
  return time?.endOf("day")?.subtract(1, "s")?.toISOString(true);
};

export const startOfMomentToISOString = (time, useUtc = false) => {
  const timeCopy = time?.clone();
  return useUtc ? timeCopy?.utc().toISOString(true) : timeCopy?.toISOString(true);
};

export const endOfMomentToISOString = (time, useUtc = false) => {
  const timeCopy = time?.clone();
  return useUtc ? timeCopy?.utc().toISOString(true) : timeCopy?.toISOString(true);
};

export const convertIsoStringToDate = (date) => {
  return formatDateTime(date, DATE_FORMAT);
};

export const convertIsoStringToDateTime = (date) => {
  return formatDateTime(date, DATE_TIME_FORMAT);
};

export const convertTimeDate = (value, format) => {
  if (value === null) {
    return "";
  } else {
    return formatDateTime(value, format);
  }
};
export const getLocalTimezone = () => {
  const currentDate = new Date();
  const timezoneOffset = currentDate.getTimezoneOffset();
  return -timezoneOffset / 60;
};

export const convertTimeDateUTC = (dateTime) => {
  const date = new Date(dateTime);
  const currentTime = date.getTime();
  const newTime = currentTime - getLocalTimezone() * 60 * 60 * 1000; //convert to milliseconds
  const newDate = new Date(newTime);
  const year = newDate.getUTCFullYear();
  const month = newDate.getUTCMonth() + 1;
  const day = newDate.getUTCDate();
  const hour = newDate.getUTCHours();
  const minute = newDate.getUTCMinutes();
  const second = newDate.getUTCSeconds();
  return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}T${hour
    .toString()
    .padStart(2, "0")}:${minute.toString().padStart(2, "0")}:${second.toString().padStart(2, "0")}.000Z`;
};
