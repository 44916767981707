import {
  AutoComplete,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  TimePicker,
  TreeSelect,
  Upload,
} from "antd";
import useSelectLoadMore from "components/CustomSelect/SelectLoadMore/useSelectLoadMore";
import StateSwitch from "components/StateSwitch";
import { ACCEPT_IMG_TYPES, INPUT_TYPE, REGEX } from "config/constants";
import { useTranslation } from "react-i18next";
import { dummyRequest, notify, validateFile } from "utils/helperFuncs";
import "./index.scss";
const { RangePicker } = DatePicker;

const FormInput = (props) => {
  const {
    type,
    formItemOptions,
    inputOptions,
    inputChildren,
    onLoadMore,
    hasMoreData,
    onSearch,
    onChange,
    fileList,
    onPreview,
    onChangeImg,
    showTime,
  } = props;
  const { t } = useTranslation();
  const { handlePopupScroll, handleSearch, handleChange } = useSelectLoadMore({
    onLoadMore,
    hasMoreData,
    onSearch,
    onChange,
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const renderInput = (type) => {
    switch (type) {
      case INPUT_TYPE.TEXT_AREA:
        return <Input.TextArea {...inputOptions} />;
      case INPUT_TYPE.NUMBER:
        return <InputNumber {...inputOptions} onKeyPress={handleKeyPress} />;
      case INPUT_TYPE.CURRENCY_INPUT:
        return (
          <InputNumber
            {...inputOptions}
            controls={false}
            formatter={(value) => value.replace(REGEX.CURRENCY, ".")}
            parser={(value) => value.replace(REGEX.CURRENCY_PARSER, "")}
            onKeyPress={handleKeyPress}
          />
        );
      case INPUT_TYPE.SELECT:
        return <Select {...inputOptions}>{inputChildren}</Select>;
      case INPUT_TYPE.SELECT_LOAD_MORE:
        return (
          <Select
            showSearch
            style={{
              width: 450,
            }}
            onChange={handleChange}
            allowClear
            onPopupScroll={handlePopupScroll}
            onSearch={handleSearch}
            filterOption={false}
            {...inputOptions}
          />
        );
      case INPUT_TYPE.DATE_PICKER:
        return <DatePicker {...inputOptions} />;
      case INPUT_TYPE.PASSWORD:
        return <Input.Password {...inputOptions} />;
      case INPUT_TYPE.CHECK_BOX:
        return <Checkbox {...inputOptions}>{inputChildren}</Checkbox>;
      case INPUT_TYPE.CHECK_BOX_GROUP:
        return <Checkbox.Group {...inputOptions}>{inputChildren}</Checkbox.Group>;
      case INPUT_TYPE.RADIO_GROUP:
        return <Radio.Group {...inputOptions}>{inputChildren}</Radio.Group>;
      case INPUT_TYPE.TIME_PICKER:
        return <TimePicker {...inputOptions} />;
      case INPUT_TYPE.SWITCH:
        return <StateSwitch {...inputOptions} />;
      case INPUT_TYPE.TREE_SELECT:
        return <TreeSelect {...inputOptions} />;
      case INPUT_TYPE.IMAGE_UPLOAD:
        return (
          <Upload
            accept={ACCEPT_IMG_TYPES.join(",")}
            listType="picture-card"
            customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
            beforeUpload={(file) => {
              if (!validateFile(file)) {
                notify.error({ message: t("uploadDocument.limitErrorMessage") });
                return Upload.LIST_IGNORE;
              }
              return validateFile(file);
            }}
            fileList={fileList}
            onPreview={onPreview}
            onChange={onChangeImg}
            {...inputOptions}
          >
            {inputChildren}
          </Upload>
        );
      case INPUT_TYPE.RANGE_PICKER:
        return <RangePicker {...inputOptions} showTime={showTime} />;
      case INPUT_TYPE.AUTO_COMPLETE:
        return <AutoComplete {...inputOptions}>{inputChildren}</AutoComplete>;
      default:
        return <Input {...inputOptions} onKeyPress={handleKeyPress} />;
    }
  };

  return (
    <Form.Item className="custom-form-item" {...formItemOptions}>
      {renderInput(type)}
    </Form.Item>
  );
};

export default FormInput;
