import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "assets/icons";
import { USER_PERMISSIONS } from "config/constants";
import KeyList from "features/KeyManagement/KeyList/index";
import SuggestedEntry from "features/KeyManagement/SuggestedEntry";
import CreateExportKey from "features/KeyManagement/SuggestedEntry/CreateExportKey";

const router = [
  {
    key: "key-management",
    title: "common.keyManagementTitle",
    icon: <FontAwesomeIcon icon={Icon.faKey} />,
    permissions: [USER_PERMISSIONS.KEY_LICENSE_ACCOUNT_VIEW_ALL],
    children: [
      {
        key: "key-list",
        title: "common.keyManagementCodeList",
        path: "/key-list",
        exact: true,
        component: KeyList,
      },
      {
        key: "suggested-entry",
        title: "common.keyManagementSuggestedEntry",
        path: "/suggested-entry",
        exact: true,
        component: SuggestedEntry,
      },
    ],
  },
  {
    key: "create-export-key",
    path: "/create-export-key",
    exact: true,
    component: CreateExportKey,
  },
];

export default router;
