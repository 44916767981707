import { Col, Form, Radio, Row, Space, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { SvgIcon } from "assets/icons";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { useCancelOrder } from "hooks/order";
import { has } from "lodash";
import { CANCEL_ORDER_REASON } from "pages/App/Order/conts";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { notify } from "utils/helperFuncs";
import "./index.scss";

const CustomModalCancelOrder = ({ setOpenModalCancelOrder, orderIDs, resetCheckedList, selectedList }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [hidden, setHidden] = useState(true);
  const { handleCancelOrder, loading } = useCancelOrder();

  const cancelOptions = [
    { label: t("order.cancelOrder.lackOfStock"), value: CANCEL_ORDER_REASON.LACK_OF_STOCK },
    { label: t("order.cancelOrder.outOfDebt"), value: CANCEL_ORDER_REASON.OUT_OF_DEBT },
    { label: t("order.cancelOrder.unableToPreOrder"), value: CANCEL_ORDER_REASON.UNABLE_TO_PRE_ORDER },
    { label: t("order.cancelOrder.cannotBeSoldAtThisPrice"), value: CANCEL_ORDER_REASON.CAN_NOT_BE_SOLD_AT_THIS_PRICE },
    { label: t("bank_transfer.order.overdue_order_payment"), value: CANCEL_ORDER_REASON.OVERDUE_ORDER_PAYMENT },
    {
      label: "Thay đổi thông tin đơn hàng (Địa chỉ, phương thức vận chuyển, phương thức thanh toán,khuyến mãi,...)",
      value: CANCEL_ORDER_REASON.CHANGE_ORDER_INFORMATION,
    },
    {
      label: "Thay đổi sản phẩm trong đơn hàng (Mã sản phẩm, số lượng, ...)",
      value: CANCEL_ORDER_REASON.CHANGE_PRODUCTS_IN_THE_ORDER,
    },
    {
      label: "Khách hàng không có nhu cầu mua nữa",
      value: CANCEL_ORDER_REASON.CUSTOMERS_NO_LONGER_NEED_TO_BUY,
    },
    { label: "Lý do khác", value: CANCEL_ORDER_REASON.OTHER },
  ];

  const cancelReason = {
    [`${CANCEL_ORDER_REASON.LACK_OF_STOCK}`]: t("order.cancelOrder.lackOfStock"),
    [`${CANCEL_ORDER_REASON.OUT_OF_DEBT}`]: t("order.cancelOrder.outOfDebt"),
    [`${CANCEL_ORDER_REASON.UNABLE_TO_PRE_ORDER}`]: t("order.cancelOrder.unableToPreOrder"),
    [`${CANCEL_ORDER_REASON.CAN_NOT_BE_SOLD_AT_THIS_PRICE}`]: t("order.cancelOrder.cannotBeSoldAtThisPrice"),
    [`${CANCEL_ORDER_REASON.OVERDUE_ORDER_PAYMENT}`]: t("bank_transfer.order.overdue_order_payment"),
    [`${CANCEL_ORDER_REASON.CHANGE_ORDER_INFORMATION}`]:
      "Thay đổi thông tin đơn hàng (Địa chỉ, phương thức vận chuyển, phương thức thanh toán,khuyến mãi,...)",
    [`${CANCEL_ORDER_REASON.CHANGE_PRODUCTS_IN_THE_ORDER}`]:
      "Thay đổi sản phẩm trong đơn hàng (Mã sản phẩm, số lượng,...)",
    [`${CANCEL_ORDER_REASON.CUSTOMERS_NO_LONGER_NEED_TO_BUY}`]: "Khách hàng không có nhu cầu mua nữa",
  };

  const handleConfirm = async () =>
    await form.validateFields().then(
      async (values) =>
        await handleCancelOrder({
          orderIDs: orderIDs,
          reason:
            values.reason === CANCEL_ORDER_REASON.OTHER
              ? `Lý do khác: ${values.freeText}`
              : cancelReason[`${values.reason}`],
        })
          .then(() => {
            notify.success({
              message: t("order.cancelOrder.cancelOrderSuccess"),
            });
            resetCheckedList && resetCheckedList();
            setOpenModalCancelOrder(false);
          })
          .catch((error) => {
            notify.error({
              message: t("order.cancelOrder.cancelOrderError"),
              description: error.message,
            });
          })
    );

  const handleValuesChange = (changedValues, allValues) => {
    const formValues = form.getFieldsValue();
    if (has(changedValues, "reason")) {
      if (changedValues.reason === "OTHER") {
        setHidden(false);
      } else {
        setHidden(true);
        form.setFieldsValue({ ...formValues, freeText: "" });
      }
    }
  };

  const handleCancel = () => {
    setOpenModalCancelOrder(false);
  };

  const warningMessage = () => {
    const isKeyLicenseArray = selectedList?.map((itemList) =>
      itemList?.items?.some((item) => item?.product?.productType !== "MATERIAL")
    );

    const hasTrue = isKeyLicenseArray?.some((value) => value === true);

    if (hasTrue) {
      return `Lưu ý: Đơn hàng của bạn chứa 02 loại sản phẩm: key license/ account và sản phẩm thông thường. Nếu bạn Xác nhận hủy đơn, hệ thống sẽ hủy toàn bộ đơn hàng!`;
    }
    return `Nếu bạn Xác nhận hủy đơn, trạng thái của đơn hàng sẽ chuyển sang Đã hủy!`;
  };

  const hasPreOrderItem = selectedList?.some((item) => item?.campaignType === "PRE_ORDER");

  const statusPreOrder = () => {
    const listPreOrderItem =
      selectedList && selectedList[0].items.filter((i) => i?.campaignInfo?.campaignType === "PRE_ORDER");
    const campaignTimingStatus = listPreOrderItem && listPreOrderItem[0]?.campaignInfo?.campaign?.timingStatus;

    if (campaignTimingStatus === "ON_GOING") {
      return "Đang diễn ra";
    } else if (campaignTimingStatus === "UP_COMING") {
      return "Sắp diễn ra";
    } else {
      return "Đã kết thúc";
    }
  };

  return (
    <CustomModal
      title={<div className="cancel-order-title">{t("order.cancelOrder.tittle")}</div>}
      closable={false}
      centered={true}
      footer={false}
      isBlockCloseOnOke
      selfVisibleControlled={false}
      onOke={handleConfirm}
      buttonLoading={loading}
      onCancel={handleCancel}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {hasPreOrderItem ? (
            <Space style={{ width: "100%", justifyContent: "center" }}>
              <Row style={{ display: "flex", alignItems: "start", gap: "8px", flexWrap: "nowrap" }}>
                <SvgIcon.HelpIcon width={36} />
                <Typography.Text type="warning">
                  Lưu ý: Đơn hàng này có loại đơn là Khuyến mãi đặt trước, thuộc chương trình đặt trước {""}
                  {statusPreOrder()}. Sau khi huỷ đơn thành công, hệ thống sẽ cập nhật lại thông tin về tiến độ đặt
                  hàng.
                </Typography.Text>
              </Row>
            </Space>
          ) : (
            <Space style={{ width: "100%", justifyContent: "center" }}>
              <Typography.Text type="warning">{warningMessage()}</Typography.Text>
            </Space>
          )}
        </Col>
        <Col span={24}>
          <Form form={form} onValuesChange={handleValuesChange}>
            <FormInput
              type={INPUT_TYPE.RADIO_GROUP}
              formItemOptions={{
                name: "reason",
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("reason")) {
                        return Promise.resolve();
                      }
                      return Promise.reject(notify.warning({ message: t("order.cancelOrder.warningMessage") }));
                    },
                  }),
                ],
                style: { marginBottom: 0 },
              }}
              inputChildren={
                <Space direction="vertical">
                  {cancelOptions.map((item, index) => (
                    <Radio key={item.value} value={item.value}>
                      {item.label}
                    </Radio>
                  ))}
                </Space>
              }
            />
            <FormInput
              type={INPUT_TYPE.TEXT_AREA}
              formItemOptions={{
                name: "freeText",
                hidden,
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("reason") !== "OTHER") {
                        return Promise.resolve();
                      }
                      if (getFieldValue("reason") === "OTHER" && value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("order.cancelOrder.otherFieldCannotEmpty")));
                    },
                  }),
                ],
                style: { marginBottom: 0, marginLeft: 20 },
              }}
              inputOptions={{ placeholder: t("order.cancelOrder.otherPlaceholder"), maxLength: 255 }}
            />
          </Form>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default CustomModalCancelOrder;
