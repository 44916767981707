import { UserManager, WebStorageStateStore } from "oidc-client-ts";

// client id, the client should not specify secret because of SPA app cannot keep the client secret securely.
export const OAUTH2_CLIENT_ID = process.env.REACT_APP_OAUTH2_CLIENT_ID;
// route handle by oidc library
export const OAUTH2_REDIRECT_URI =
  process.env.REACT_APP_OAUTH2_REDIRECT_URI || `http://localhost:3000/authentication/callback`;

// must contain openid for logout function working properly
export const OAUTH2_SCOPE = process.env.REACT_APP_OAUTH2_SCOPE || `openid offline_access`;

// public url of hydra/ auth server
export const OAUTH2_SERVER_URL = process.env.REACT_APP_OAUTH2_SERVER_URL || `https://auth.dev.honganh.vn`;

export const OAUTH2_ENABLED = process.env.REACT_APP_NEW_AUTH_FLOW === "true";

export const authZitadelConfig = {
  authority: process.env.REACT_APP_OAUTH2_SERVER_URL,
  client_id: process.env.REACT_APP_OAUTH2_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_OAUTH2_REDIRECT_URI,
  response_type: "code",
  scope: "openid profile email offline_access",
  post_logout_redirect_uri: process.env.REACT_APP_OAUTH2_LOGOUT_REDIRECT_URI,
  response_mode: "query",
  code_challenge_method: "S256",
  silent_redirect_uri: process.env.REACT_APP_OAUTH2_REDIRECT_URI,
};

export const authZitadelManager = new UserManager({
  userStore: new WebStorageStateStore(),
  ...authZitadelConfig,
});
