/* eslint-disable array-callback-return */
import { Typography } from "antd";
import EllipsisText from "components/_storybooks/EllipsisText";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import { FilterLoadMore } from "components/CustomFilterMenu/FilterLoadMore";
import { useGetProductPriceLog } from "hooks/product/product";
import useDebounce from "hooks/useDebounce";
import { useGetStaff } from "hooks/warranty";
import { t } from "i18next";
import { isNil } from "lodash";
import { CheckVatType, VAT_TYPE } from "pages/App/Product/constants";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { buildQueryString, formatCurrency, getQuery } from "utils/helperFuncs";
import { omit } from "utils/object";
import file_logo from "../../../../../assets/icons/file_logo.svg";

const useList = () => {
  const GET_QUERY = getQuery();
  const [filterTags, setFilterTags] = useState([]);
  const [params, setParams] = useState(() => {
    return {
      filters: {
        query: "",
        categoryIDs: GET_QUERY.categoryIDs || undefined,
        createdByIDs: GET_QUERY.createdByIDs || undefined,
        createdAtFilter: { from: GET_QUERY?.from, to: GET_QUERY?.to },
        orderBy: GET_QUERY.orderBy || "CREATED_AT_DESC",
      },
      pagination: {
        offset: GET_QUERY.offset || 0,
        limit: GET_QUERY.limit || 10,
      },
    };
  });

  const { data, paginationData, loading } = useGetProductPriceLog(params);
  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });

  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(paramsStaff);

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const checkNullValue = (value) => {
    if (value === null || value === "" || value === undefined) {
      return "--";
    } else {
      return value;
    }
  };

  const showChangeVat = (type, oldValue, newValue) => {
    let oldValueShowed = showVatValue(oldValue),
      newValueShowed = showVatValue(newValue);

    if (!oldValueShowed && !newValueShowed) {
      return "--";
    }

    switch (type) {
      case t("product.%VAT"):
        return showVatPercent(oldValueShowed, newValueShowed);
      case t("product.differenceVAT"):
        return showVatDiff(oldValueShowed, newValueShowed);
    }
  };

  const showVatValue = (value) => {
    if (!value || value >= 0) {
      return value;
    } else {
      return t("v2.product.drawer_edit_product.not_taxable_shortcut");
    }
  };

  const showVatPercent = (oldValue, newValue) => {
    let oldValueWithPercent = isNaN(oldValue) ? oldValue : `${oldValue} %`,
      newValueWithPercent = isNaN(newValue) ? newValue : `${newValue} %`;

    return checkValueDuplicate(oldValueWithPercent, newValueWithPercent);
  };

  const showVatDiff = (oldValue, newValue) => {
    let oldValueWithCurrency = isNaN(oldValue) ? oldValue : formatCurrency(oldValue),
      newValueWithCurrency = isNaN(newValue) ? newValue : formatCurrency(newValue);

    return checkValueDuplicate(oldValueWithCurrency, newValueWithCurrency);
  };

  const checkValueDuplicate = (oldValue, newValue) => {
    if (oldValue?.toString() === newValue?.toString()) {
      return oldValue;
    } else
      return (
        <span style={{ textWrap: "nowrap" }}>
          {checkNullValue(oldValue)}&nbsp;&#10142;&nbsp;
          <span style={{ color: "#32BF46" }}>{checkNullValue(newValue)}</span>
        </span>
      );
  };

  const checkValueDuplicateDiscount = (oldValue, newValue) => {
    if (oldValue === newValue) {
      return newValue;
    }
    if (oldValue || newValue) {
      return (
        <span style={{ textWrap: "nowrap" }}>
          {oldValue || "--"}&nbsp;&#10142;&nbsp;
          <span style={{ color: "#32BF46" }}>{newValue || "--"}</span>
        </span>
      );
    }
    return "--";
  };

  let result = [];
  const handleFilter = (values) => {
    values?.createdByIDs.map((val) => {
      const arr = staffs.filter((item) => item.value === val);
      result.push(arr);
    });
    setFilterTags(result);
    const newParams = {
      ...params,
      pagination: {
        offset: 0,
        limit: 10,
      },
      filters: {
        query: params?.filters?.query,
        ...values,
        categoryIDs: params?.filters?.categoryIDs,
        createdAtFilter: {
          from: params?.filters?.createdAtFilter?.from,
          to: params?.filters?.createdAtFilter?.to,
        },
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...values,
        ...newParams?.pagination,
        query: newParams?.filters?.query,
        from: newParams?.filters?.createdAtFilter?.from,
        to: newParams?.filters?.createdAtFilter?.to,
        categoryIDs: newParams?.filters?.categoryIDs,
        createdByIDs: newParams?.filters?.createdByIDs,
      },
    });
  };
  const handleRemoveFilterTag = (key) => {
    setParams({
      pagination: { offset: 0, limit: 10 },
      filters: {
        ...omit({ ...params.filters }, [`${key}`]),
      },
    });
    buildQueryString({
      params: {
        ...omit({ ...params?.filters }, [`${key}`]),
        ...params?.pagination,
      },
    });
  };

  const handleDownloadFile = (fileURL) => {
    window.open(fileURL);
  };

  const columns = [
    {
      title: <span>{t("product.time")}</span>,
      sorter: {
        compare: (a, b) => a.createdAt.localeCompare(b.createdAt),
        multiple: 1,
        sortDirections: ["ascend", "descend"],
      },
      width: 120,
      align: "center",
      fixed: "left",
      render: (_, record, index) => checkNullValue(formatDateTime(record?.createdAt)),
    },
    {
      width: 300,
      title: "Thông tin sản phẩm",
      fixed: "left",
      render: (_, record) => {
        return (
          <SpaceCustom>
            <Link target="_blank" to={`product/detail/${record?.productID}`}>
              <EllipsisText
                isLink
                copyable={{
                  text: record.product.name,
                }}
                line={1}
                strong
                title={record?.product?.code}
              />
            </Link>
            <EllipsisText line={3} title={record.product.name} />
          </SpaceCustom>
        );
      },
    },
    {
      title: "GIÁ CHƯA VAT",
      align: "right",
      width: 250,
      render: (_, record) => checkValueDuplicate(formatCurrency(record?.oldPrice), formatCurrency(record?.newPrice)),
    },
    {
      title: t("product.EXPORT_VAT"),
      align: "center",
      width: 150,
      render: (_, record) => {
        return record?.oldVatType === record?.newVatType ? (
          <span>{CheckVatType(record?.oldVatType)}</span>
        ) : (
          <span style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
            <span className="icon-old-vat">{CheckVatType(record?.oldVatType)}</span>
            &nbsp;&#10142;&nbsp;
            <span className="icon-new-vat">{CheckVatType(record?.newVatType)}</span>
          </span>
        );
      },
    },
    {
      title: t("product.differenceVAT"),
      align: "right",
      width: 250,
      render: (_, record) => showChangeVat(t("product.differenceVAT"), record?.oldVatValue, record?.newVatValue),
    },
    {
      title: "LOẠI  % VAT ",
      align: "center",
      width: 150,
      render: (_, record) => showChangeVat(t("product.%VAT"), record?.oldVatPercent, record?.newVatPercent),
    },
    {
      title: "GIÁ CÓ VAT",
      align: "right",
      width: 250,
      render: (_, record) => {
        switch (record?.newVatType) {
          case VAT_TYPE.NOT_REQUIRED:
            return record?.newPrice ? "sp không xuất VAT" : "- -";
          case VAT_TYPE.REQUIRED:
          case VAT_TYPE.OPTIONAL:
            return (
              <div>{checkValueDuplicate(formatCurrency(record?.oldPriceVat), formatCurrency(record?.newPriceVat))}</div>
            );
        }
      },
    },
    {
      title: "THÔNG TIN CHÍNH SÁCH GIÁ",
      align: "center",
      children: [
        {
          title: t("product.levelQuantity", { level: "1" }),
          align: "center",
          width: 150,
          render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel1Quantity, record?.newLevel1Quantity),
        },
        {
          title: t("product.levelDiscount", { level: "1" }),
          align: "right",
          width: 250,
          render: (_, record) =>
            checkValueDuplicate(
              record?.oldLevel1Discount ? formatCurrency(record?.oldLevel1Discount) : "--",
              record?.newLevel1Discount ? formatCurrency(record?.newLevel1Discount) : "--"
            ),
        },
        {
          title: t("product.levelQuantity", { level: "2" }),
          align: "center",
          width: 150,
          render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel2Quantity, record?.newLevel2Quantity),
        },
        {
          title: t("product.levelDiscount", { level: "2" }),
          align: "right",
          width: 250,
          render: (_, record) =>
            checkValueDuplicate(
              record?.oldLevel2Discount ? formatCurrency(record?.oldLevel2Discount) : "--",
              record?.newLevel2Discount ? formatCurrency(record?.newLevel2Discount) : "--"
            ),
        },
        {
          title: t("product.levelQuantity", { level: "3" }),
          align: "center",
          width: 150,
          render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel3Quantity, record?.newLevel3Quantity),
        },
        {
          title: t("product.levelDiscount", { level: "3" }),
          align: "right",
          width: 250,
          render: (_, record) =>
            checkValueDuplicate(
              record?.oldLevel3Discount ? formatCurrency(record?.oldLevel3Discount) : "--",
              record?.newLevel3Discount ? formatCurrency(record?.newLevel3Discount) : "--"
            ),
        },
        {
          title: t("product.levelQuantity", { level: "4" }),
          align: "center",
          width: 150,
          render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel4Quantity, record?.newLevel4Quantity),
        },
        {
          title: t("product.levelDiscount", { level: "4" }),
          align: "right",
          width: 250,
          render: (_, record) =>
            checkValueDuplicate(
              record?.oldLevel4Discount ? formatCurrency(record?.oldLevel4Discount) : "--",
              record?.newLevel4Discount ? formatCurrency(record?.newLevel4Discount) : "--"
            ),
        },
      ],
    },
    {
      title: t("product.PEOPLE_CHANGE"),
      render: (_, record, index) => checkNullValue(record?.createdBy?.fullname),
      width: 200,
      filterDropdown: (
        <FilterLoadMore
          params={params}
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          onFilter={(value) => handleFilter({ createdByIDs: value })}
          onSearchTextChange={handleSearchStaffs}
          hasMore={hasMoreData}
        />
      ),
    },
    {
      title: t("product.ATTACHMENT"),
      fixed: "right",
      align: "center",
      width: 100,
      render: (_, record, index) => (
        <Typography.Link>
          <img src={file_logo} alt="" />
          &nbsp;
          <div onClick={() => handleDownloadFile(record?.priceImportSession?.fileURL)}>
            {record?.priceImportSession?.fileName}
          </div>
        </Typography.Link>
      ),
    },
  ];

  const [searchTerm, setSearchTerm] = useState(GET_QUERY.query);
  const debouncedValue = useDebounce(searchTerm);

  const onSearchPriceLog = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChangeTablePriceLogs = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      filters: {
        query: params?.filters?.query,
        categoryIDs: params?.filters?.categoryIDs,
        createdByIDs: params?.filters?.createdByIDs,
        createdAtFilter: {
          from: params?.filters?.createdAtFilter?.from,
          to: params?.filters?.createdAtFilter?.to,
        },
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.pagination,
        query: newParams?.filters?.query,
        from: newParams?.filters?.createdAtFilter?.from,
        to: newParams?.filters?.createdAtFilter?.to,
        categoryIDs: newParams?.filters?.categoryIDs,
        createdByIDs: newParams?.filters?.createdByIDs,
      },
    });
  };

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      const newParams = {
        ...params,
        pagination: {
          offset: 0,
          limit: 10,
        },
        filters: {
          query: debouncedValue,
          createdAtFilter: {
            from: params?.filters?.createdAtFilter?.from,
            to: params?.filters?.createdAtFilter?.to,
          },
          categoryIDs: params?.filters?.categoryIDs,
          createdByIDs: params?.filters?.createdByIDs,
        },
      };
      setParams(newParams);
      buildQueryString({
        params: {
          ...newParams?.pagination,
          query: newParams?.filters?.query,
          from: newParams?.filters?.createdAtFilter?.from,
          to: newParams?.filters?.createdAtFilter?.to,
          categoryIDs: newParams?.filters?.categoryIDs,
          createdByIDs: newParams?.filters?.createdByIDs,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  function filterProductPriceLogsByCategoryId(ids) {
    const newParams = {
      ...params,
      pagination: {
        offset: 0,
        limit: 10,
      },
      filters: {
        query: params?.filters?.query,
        createdAtFilter: {
          from: params?.filters?.createdAtFilter?.from,
          to: params?.filters?.createdAtFilter?.to,
        },
        categoryIDs: ids.length === 0 ? undefined : ids,
        createdByIDs: params?.filters?.createdByIDs,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.pagination,
        query: newParams?.filters?.query,
        from: newParams?.filters?.createdAtFilter?.from,
        to: newParams?.filters?.createdAtFilter?.to,
        categoryIDs: newParams?.filters?.categoryIDs,
        createdByIDs: newParams?.filters?.createdByIDs,
      },
    });
    return;
  }

  return {
    paginationData,
    params,
    staffs,
    setParams,
    columns,
    data,
    handleChangeTablePriceLogs,
    loading,
    onSearchPriceLog,
    filterTags,
    setFilterTags,
    filterProductPriceLogsByCategoryId,
    handleRemoveFilterTag,
    GET_QUERY,
  };
};

export default useList;
