import { Space, Tag, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import EllipsisText from "components/_storybooks/EllipsisText";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { COMBO_CAMPAIGN_LABEL } from "features/Campaign/constants";
import { ORDER_STATUS } from "features/Order/constants";
import { isOrderTypeCustomer, isRenderActualAndAvailableColumn } from "features/Order/helper";
import { isMaterial } from "features/Products/helper";
import { useOrderPermissions } from "hooks/order";
import { useGetRegionsList } from "hooks/regions";
import { useWarehouse } from "hooks/warehouse";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils/helperFuncs";

const useInfoProductAndBill = ({ dataInfoProductAndBill }) => {
  const { status, exportedWarehouseID, orderType, exportedWarehouse } = dataInfoProductAndBill;
  const { warehouses } = useWarehouse();
  const { regions } = useGetRegionsList();
  const { canPromotionOrderView } = useOrderPermissions();

  const checkOrderStatus = (statuses) => {
    return statuses.includes(status);
  };

  const isShowInStock = () => {
    return checkOrderStatus([ORDER_STATUS.WAITING_FOR_APPROVAL_ORDER, ORDER_STATUS.WAITING_FOR_APPROVAL_STOCK]);
  };

  const isShowRealInStock = () => {
    return checkOrderStatus([
      ORDER_STATUS.WAITING_FOR_PAYMENT,
      ORDER_STATUS.WAITING_FOR_PACKAGING,
      ORDER_STATUS.WAITING_FOR_EXPORTING,
      ORDER_STATUS.WAITING_FOR_ASSEMBLY,
      ORDER_STATUS.WAITING_FOR_SHIPPING,
      ORDER_STATUS.DELIVERING,
      ORDER_STATUS.COMPLETED,
      ORDER_STATUS.CANCELED,
    ]);
  };

  const isShowStockSupplier = () => {
    return checkOrderStatus([ORDER_STATUS.WAITING_FOR_APPROVAL_ORDER, ORDER_STATUS.WAITING_FOR_APPROVAL_STOCK]);
  };

  const renderStock = (quantity, quantityRealStock) => {
    return (
      <Typography>
        <Typography.Text style={{ color: "#2772f0" }}>{quantity > 0 ? quantity : "--"}</Typography.Text>
        {<Typography.Text>/{quantityRealStock > 0 ? quantityRealStock : "--"}</Typography.Text>}
      </Typography>
    );
  };

  const exportWarehouseName = warehouses?.find((item) => item.id === exportedWarehouseID)?.name?.toUpperCase();
  const regionName = regions?.find((item) => item?.id === exportedWarehouse?.regionID)?.name?.toUpperCase() || "--";

  const renderChildrenStock = warehouses?.map((warehouse, index) => ({
    title: <EllipsisText style={{ color: "#5b6673" }} title={warehouse.name} line={2} />,
    key: index,
    width: warehouse?.name?.length > 30 ? 300 : 150,
    align: "center",
    className: "table-in-stock",
    render: (_, record) => {
      if (record.children) {
        return;
      } else {
        if (isMaterial(record?.product?.productType)) {
          return renderStock(
            record?.product?.stocks?.find((stock) => stock?.warehouse?.id === warehouse?.id)?.quantity,
            record?.product?.stocks?.find((stock) => stock?.warehouse?.id === warehouse?.id)?.quantityRealStock
          );
        }
        return;
      }
    },
  }));

  const columns = [
    {
      title: t("order.orderDetail.parameter"),
      width: 250,
      fixed: "left",
      render: (text, record) => {
        if (record.children) {
          return (
            <SpaceCustom>
              <Typography.Text strong style={{ textTransform: "uppercase" }}>
                [{COMBO_CAMPAIGN_LABEL[record?.promoTier?.type]}] {record?.promoTier?.name}
              </Typography.Text>
              <Typography style={{ textAlign: "end", fontStyle: "italic" }}>Combo</Typography>
            </SpaceCustom>
          );
        }
        return (
          <SpaceCustom>
            <Link to={`/product/detail/${record.productID}`}>
              <Typography.Link strong>{record?.productCode}</Typography.Link>
            </Link>
            <CustomTextHiddenLine text={record?.productName} strong line={2} />
            <Typography style={{ textAlign: "end", fontStyle: "italic" }}>{record?.product?.uom?.name}</Typography>
          </SpaceCustom>
        );
      },
    },
    {
      title: t("order.orderDetail.warranty"),
      align: "center",
      width: 100,
      render: (text, record) => {
        if (record.children) {
          return;
        }
        return <Typography.Text>{record?.productWarranty || "--"}</Typography.Text>;
      },
    },
    {
      title: t("order.orderDetail.vatExport"),
      width: 80,
      align: "center",
      render: (text, record) => {
        if (record.children) {
          return;
        }
        return record?.vat ? <SvgIcon.SuccessIcon /> : <SvgIcon.ErrorIcon />;
      },
    },
    {
      title: t("order.orderDetail.inDebt"),
      width: 100,
      align: "center",
      render: (text, record, index) => {
        if (record.children) {
          return;
        }
        return record?.maxDaysOfDebt || "--";
      },
      isHidden: !canPromotionOrderView,
    },
    {
      title: t("order.orderDetail.quantity"),
      align: "center",
      width: 100,
      render: (text, record, index) => record?.quantity || "--",
    },
    {
      title: (
        <>
          <span>{t("order.orderDetail.availableInventory")}</span>
          <br />
          <span>({exportWarehouseName})</span>
        </>
      ),
      align: "center",
      width: 100,
      render: (text, record, index) => {
        if (record.children) {
          return;
        }
        return record?.inStock || "--";
      },
      isHidden: !canPromotionOrderView || !isShowInStock(),
    },
    {
      title: (
        <>
          <span>{t("order.orderDetail.actualInventory")}</span>
          <br />
          <span>({exportWarehouseName})</span>
        </>
      ),
      align: "center",
      width: 100,
      render: (text, record, index) => {
        if (record.children) {
          return;
        }
        return record?.realInStock || "--";
      },
      isHidden: !canPromotionOrderView || !isShowRealInStock(),
    },
    {
      title: <span>{t("order.orderDetail.actualInventory")}</span>,
      align: "center",
      width: 100,
      render: (text, record, index) => {
        if (record.children) {
          return;
        }
        return record?.realInStock || "--";
      },
      isHidden: canPromotionOrderView,
    },

    {
      title: <span>{t("order.orderDetail.availableInventory") + "/" + t("order.orderDetail.actualInventory")}</span>,
      align: "center",
      width: 200,
      children: renderChildrenStock,
      className: "table-in-stock",
      isHidden: !isRenderActualAndAvailableColumn(status),
    },
    {
      title: (
        <>
          <span>Tồn khả dụng/ Tồn VAT của NCC</span>
          <br />
          <span>({regionName})</span>
        </>
      ),
      align: "center",
      width: 100,
      render: (text, record, index) => {
        const stockSupplier = record?.product?.stocksSupplier?.filter(
          (item) => item.regionID === exportedWarehouse?.regionID
        )?.[0];
        if (record.children) {
          return;
        }
        return stockSupplier ? (
          <span>
            <span style={{ color: "#2246DC", fontWeight: "bold" }}>{stockSupplier?.totalQuantity}</span>/
            {stockSupplier?.vatQuantity}
          </span>
        ) : (
          "--"
        );
      },
      isHidden: !isShowStockSupplier(),
    },
    {
      title: t("order.orderDetail.unitPriceAgent"),
      width: 130,
      align: "right",
      render: (text, record, index) => {
        return record?.promoTier?.type === "DISCOUNT_PRODUCT" ? (
          <Space direction="vertical">
            <Typography.Text
              style={{
                textDecoration: "line-through",
                color: "#5B6673",
              }}
            >
              {formatCurrency(record?.orderItems?.[0]?.price)}
            </Typography.Text>
            <Typography.Text>{formatCurrency(record?.price)}</Typography.Text>
          </Space>
        ) : (
          <Space direction="vertical">
            {record?.productDiscount && canPromotionOrderView && (
              <Typography.Text
                style={{
                  textDecoration: "line-through",
                  color: "#5B6673",
                }}
              >
                {formatCurrency(record?.originalPrice)}
              </Typography.Text>
            )}
            {record?.originalPriceOfCombo && canPromotionOrderView && (
              <Typography.Text
                style={{
                  textDecoration: "line-through",
                  color: "#5B6673",
                }}
              >
                {formatCurrency(record?.originalPriceOfCombo)}
              </Typography.Text>
            )}
            {record?.campaignInfo?.campaignType !== "PRE_ORDER" && record?.originalPrice > 0 && (
              <Typography.Text
                style={{
                  textDecoration: "line-through",
                  color: "#5B6673",
                }}
              >
                {formatCurrency(record?.originalPrice)}
              </Typography.Text>
            )}
            <Typography.Text>{record?.price ? formatCurrency(record?.price) : "--"}</Typography.Text>
          </Space>
        );
      },
    },
    {
      title: t("order.orderDetail.intoMoneyAgent"),
      width: 130,
      render: (text, record, index) => <Typography.Link strong>{formatCurrency(record?.total)}</Typography.Link>,
      align: "right",
    },
    {
      title: t("order.orderDetail.unitPriceClient"),
      width: 130,
      align: "right",
      render: (text, record, index) => (
        <Typography>{record?.customerPrice ? formatCurrency(record?.customerPrice) : "--"}</Typography>
      ),
      isHidden: !isOrderTypeCustomer(orderType),
    },
    {
      title: t("order.orderDetail.intoMoneyClient"),
      width: 130,
      render: (text, record, index) => (
        <Typography.Link style={{ textAlign: "start" }} strong>
          {record?.customerTotal ? formatCurrency(record?.customerTotal) : "--"}
        </Typography.Link>
      ),
      align: "right",
      isHidden: !isOrderTypeCustomer(orderType),
    },
    {
      title: "Lợi nhuận của khách hàng",
      width: 140,
      fixed: "right",
      render: (text, record, index) => (
        <Tag color="#E9F9E9" style={{ color: "#0EAB10" }}>
          {formatCurrency(record?.customerTotal - record?.total)}
        </Tag>
      ),
      align: "right",
      isHidden: orderType !== "CUSTOMER",
    },
  ];
  return { columns: columns?.filter((item) => !item?.isHidden) };
};

export default useInfoProductAndBill;
