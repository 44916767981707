import { DATE_FORMAT } from "config/constants";
import { omit } from "lodash";
import moment from "moment";
import { formatDateTime, parseTimestampTimeRange } from "utils/dateTime";
import { formatCurrency } from "utils/helperFuncs";
import { PRODUCT_TYPE } from "../../features/Campaign/constants";

export const convertGetListCampaign = (data) => {
  return data?.map((item) => ({
    ...item,
    id: item?.id,
    name: item?.name,
    startDate: formatDateTime(item?.startDate),
    endDate: formatDateTime(item?.endDate),
    campaignType: item?.campaignType,
    saleChannels: item?.saleChannels,
    appliedCategories: item?.appliedCategories,
    appliedBrands: item?.appliedBrands,
    status: item?.status,
    isActive: item?.isActive,
  }));
};

export const convertPagination = (data) => {
  return {
    total: data?.total,
    pageSize: data?.limit,
    current: data?.offset / data?.limit + 1,
    showSizeChanger: false,
  };
};

export const convertDataSummaryTabs = (data) => {
  return {
    all: data?.all?.pagination?.paginationData?.total,
    upcoming: data?.upcoming?.pagination?.paginationData?.total,
    onGoing: data?.onGoing?.pagination?.paginationData?.total,
    ended: data?.ended?.pagination?.paginationData?.total,
  };
};

export const convertConditionProductGroups = (data) => {
  const newData = data?.map((itemProductGroups, indexTier) => {
    const conditionProducts = itemProductGroups?.conditionProductGroups?.filter(
      (item) => item.type === PRODUCT_TYPE.CONDITION_PRODUCT
    );
    const conditionGifts = itemProductGroups?.conditionProductGroups?.filter(
      (item) => item.type === PRODUCT_TYPE.GIFT_PRODUCT
    );

    const newItem = omit(itemProductGroups, ["conditionProductGroups"]);
    return {
      ...newItem,
      conditionProducts: conditionProducts,
      conditionGifts: conditionGifts,
    };
  });
  return newData;
};

export const convertToFormValue = (data) => {
  const parseAntUploadFiles = (url) => {
    return !!url
      ? [
        {
          uid: "-1",
          name: "campaign-image",
          status: "done",
          url: url,
        },
      ]
      : [];
  };

  const promotionCampaignTierGroups = data?.promotionCampaignTierGroups;

  const parseOptionsValue = (dataArray = []) => dataArray?.map((item) => item?.id);
  const discountMoneyIterable = promotionCampaignTierGroups?.filter((item) => item.type === "DISCOUNT_MONEY");
  const fixedPriceIterable = promotionCampaignTierGroups?.filter((item) => item.type === "FIXED_PRICE");
  const giftIterable = promotionCampaignTierGroups?.filter((item) => item.type === "GIFT");
  const discountProduct = promotionCampaignTierGroups?.filter((item) => item.type === "DISCOUNT_PRODUCT");

  const convertPromotionCampaignTierGroupsGift = giftIterable?.map((itemTier, indexTier) => {
    const newTiers = itemTier?.promotionCampaignTiers?.map((itemProductGroups, indexProductGroups) => {
      const conditionProducts = itemProductGroups?.conditionProductGroups?.filter(
        (item) => item.type === PRODUCT_TYPE.CONDITION_PRODUCT
      );
      const conditionGifts = itemProductGroups?.conditionProductGroups?.filter(
        (item) => item.type === PRODUCT_TYPE.GIFT_PRODUCT
      );
      let convertedArrayProducts = conditionProducts.map((item) => {
        const conditionPromotionProducts = item?.conditionPromotionProducts?.map((item) => {
          return {
            ...item,
            product: item?.product,
            productID: item?.product?.id,
            type: PRODUCT_TYPE.CONDITION_PRODUCT,
          };
        });
        return {
          // ...item,
          conditionPromotionProducts: conditionPromotionProducts,
          type: item?.type,
          quantity: item?.quantity,
        };
      });

      function convertDataConditionGifts(data) {
        return data.map((item) => {
          const convertedItem = {
            ...item,
            ...item?.conditionPromotionProducts?.reduce((acc, subItem, index) => {
              acc[index] = {
                budgetQuantity: subItem.budgetQuantity,
                productWarranty: subItem.productWarranty,
              };
              return acc;
            }, {}),
            conditionPromotionProducts: item?.conditionPromotionProducts?.map((subItem) => ({
              ...subItem,
              type: PRODUCT_TYPE.GIFT_PRODUCT,
              productID: subItem.productID,
            })),
          };
          return convertedItem;
        });
      }

      const convertedArrayGifts = convertDataConditionGifts(conditionGifts);

      const newItem = omit(itemProductGroups, ["conditionProductGroups"]);
      return {
        ...newItem,
        conditionProducts: convertedArrayProducts,
        conditionGifts: convertedArrayGifts,
      };
    });

    return {
      ...itemTier,
      promotionCampaignTiers: newTiers,
    };
  });

  return {
    promoName: data?.name,
    type: data?.campaignType,
    id: data?.id,
    period: parseTimestampTimeRange(data?.startDate, data?.endDate),
    bannerDesktop: parseAntUploadFiles(data?.banner?.imageURL),
    bannerMobile: parseAntUploadFiles(data?.banner?.mobileURL),
    link: data?.link,
    description: data?.description,
    dueDate: !!data?.maxOrderCompletedDate ? moment(data.maxOrderCompletedDate) : undefined,
    minTotal: data?.minTotalOrderValue,
    categories: parseOptionsValue(data?.appliedCategories),
    brands: parseOptionsValue(data?.appliedBrands),
    promoType: data?.campaignType,
    buyChannels: parseOptionsValue(data?.saleChannels),
    highlight: data?.highlight,
    promotionCampaignTierGroups: promotionCampaignTierGroups,
    isActive: data?.isActive,
    applyWithOtherPromoTypes: data?.applyWithOtherPromoTypes,
    totalBudget: data?.totalBudget,
    applyWithOtherPromo: data?.applyWithOtherPromoTypes?.length && true,
    promotionCampaignTierGroupsDiscountMoney: discountMoneyIterable,
    promotionCampaignTierGroupsFixedPrice: fixedPriceIterable,
    promotionCampaignTierGroupsGift: convertPromotionCampaignTierGroupsGift,
    promotionCampaignTierGroupsProductPromo: discountProduct,
  };
};

export const convertDataOrderInPromo = (data) => {
  return data?.map((item) => ({
    id: item?.id,
    createdAt: formatDateTime(item?.createdAt, DATE_FORMAT),
    orderCode: item?.orderCode,
    orderID: item?.orderID,
    saleChannel: item?.saleChannel,
    sellerName: item?.sellerName,
    status: item?.status,
    total: formatCurrency(item?.total),
  }));
};

export const convertGetSellerName = (data) => {
  return data?.map((item) => ({
    value: item?.id,
    label: item?.fullName,
  }));
};
