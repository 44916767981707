import { gql } from "@apollo/client";

export const GET_ORDERS_QUERY = gql`
  query GetOrders($filters: OrdersFilters!, $pagination: PaginationRequest!, $sort: [OrderSort!]) {
    order {
      pagination(filters: $filters, sort: $sort, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        orders {
          items {
            campaignInfo {
              campaignType
              campaign {
                timingStatus
              }
            }
            productID
            productCode
            productName
            productWarranty
            price
            quantity
            product {
              productType
              name
              uom {
                name
              }
              stocks {
                productID
                quantity
                quantityRealStock
                warehouse {
                  id
                  code
                  name
                  regionID
                }
              }
            }
          }
          id
          orderType
          createdAt
          number
          subTotal
          total
          campaignType
          customerTotal
          seller {
            warehouses {
              id
              name
            }
          }
          exportedWarehouse {
            name
            id
          }
          paymentStatus
          receiverContactName
          receiverContactAddress
          orderPriority
          shippingType
          items {
            product {
              productType
            }
          }
          paymentMethod
          status
          createdBy {
            email
            fullname
            id
            type
          }
          fullStockRequired
          remainingDeliverTime
          vat
          deliveryDeadline
          chatURL
          shippingConfiguration {
            busConfig {
              busName
              busStation
              telephone
              location
              fee
            }
            deliver3PartyConfig {
              name
              weight
              length
              width
              height
              fee
              carrierMethod
              provider {
                id
                name
              }
            }
            urbanConfig {
              fee
            }
          }
          internalNote
          stockStatus
          completedAt
          fileURLs
          senderAddress
          shipperTelephone
          shipperName
          receiverContactPhone
          note
          assemblyRequired
          cancelReason
          preOrder
          paymentInfo {
            sellerConfirmPaid
          }
          saleChannel {
            name
            type
          }
          fullEvents {
            createdBy {
              type
            }
          }
          messages {
            type
            content
            actions
          }
          seller {
            approvalStatus
          }
        }
      }
    }
  }
`;

export const GET_WAREHOUSES = gql`
  query {
    warehouse {
      list {
        warehouses {
          name
          id
        }
      }
    }
  }
`;

export const GET_WAREHOUSES_WITH_IDS = gql`
  query GetWarehouseWithIds($request: WarehouseRequest) {
    warehouse {
      list(request: $request) {
        warehouses {
          name
          id
        }
      }
    }
  }
`;

export const GET_CREATED_BY = gql`
  query GetCreatedBy($pagination: PaginationRequest, $filters: UsersFilters) {
    user {
      pagination(pagination: $pagination, filters: $filters) {
        paginationData {
          total
          offset
          limit
        }
        users {
          id
          fullname
        }
      }
    }
  }
`;

export const GET_ORDER_DETAIL_QUERY = gql`
  query GetOrder($id: ID!, $itemDataType: OrderItemDataType) {
    order {
      get(id: $id) {
        id
        status
        orderParcel {
          shippingCost
          trackingCode
          shippingProviderServiceName
          shippingProviderServiceCode
          shippingStatus
          shippingProviderStatus
          trackingUrl
        }
        shippingVersion
        number
        campaignType
        accountingNumber
        vatTaxIDNumber
        vatCompanyName
        vatEmail
        vatAddress
        customerVatEmail
        vatCustomerName
        customerVatIDNumber
        customerVatAddress
        preOrder
        shippingStatus
        exportedWarehouse {
          id
          name
          regionID
        }
        saleChannel {
          name
          type
        }
        totalPromotionDiscountMoney
        customerShippingFee
        customerTotalServiceProduct
        sellerProfitAmount
        orderReconciliationStatus
        items(itemDataType: $itemDataType) {
          id
          productID
          productName
          productCode
          quantity
          price
          customerPrice
          total
          customerTotal
          maxDaysOfDebt
          productWarranty
          warrantyDateUnit
          inStock
          realInStock
          campaignInfo {
            campaignType
            campaign {
              timingStatus
            }
          }
          product {
            stocksSupplier {
              regionID
              region {
                name
              }
              totalQuantity
              vatQuantity
            }
            stocks {
              productID
              quantity
              quantityRealStock
              warehouse {
                id
                code
                name
                regionID
              }
            }
            productType
          }
          vat
          ... on OrderItem {
            preOrder
            originalPrice
            price
            productDiscount
          }
          ... on ComboOrderItem {
            originalPriceOfCombo
            comboOrderItemID
            productID
            promoTierID
            discountMoney
            campaignInfo {
              campaignType
              campaign {
                timingStatus
              }
            }
            promoTier {
              discountAmount
              discountType
              type
              name
            }
            orderItems(showOriginComboItemQuantity: false) {
              product {
                uom {
                  id
                  name
                }
                stocks {
                  productID
                  quantity
                  warehouse {
                    id
                    code
                    name
                    regionID
                  }
                }
                productType
              }
              id
              productName
              quantity
              vat
              inStock
              price
              total
              productID
              productCode
              quantity
              customerPrice
              customerTotal
              maxDaysOfDebt
              productWarranty
              warrantyDateUnit
              realInStock
            }
          }
          product {
            uom {
              name
            }
            stocks {
              productID
              quantity
              warehouse {
                id
                code
                name
                regionID
              }
            }
            priceOfSeller {
              price
              level
            }
            productType
          }
        }
        sellerID
        seller {
          email
          shortName
          telephone
          address
          debtLimit
          availabilityLimit
          currentAvailable
          fullName
          isActive
          vatInfo {
            address
            email
            taxIDNumber
            businessName
          }
          companyWallet {
            balance
          }
          warehouses {
            id
            name
          }
          approvalStatus
          chatwootContact {
            id
            url
          }
          users {
            id
            fullname
            email
            telephone
            status
            chatwootContact {
              id
              url
            }
          }
        }
        messages {
          type
          content
          actions
        }
        createdByID
        note
        internalNote
        shippingType
        shippingConfiguration {
          busConfig {
            busName
            telephone
            location
            busStation
            fee
          }
          deliver3PartyConfig {
            name
            fee
            serviceName
            carrierMethod
            carrierMaxFee
            carrierMinFee
            provider {
              id
              name
            }
          }
          urbanConfig {
            fee
          }
        }
        orderPriority
        deliveryDeadline
        chatURL
        remainingDeliverTime
        fullStockRequired
        shippingFee
        serviceFee
        discount
        exportedWarehouseID
        vat
        vatAllOrder
        discount
        subTotal
        customerSubTotal
        customerTotal
        senderName
        senderPhone
        senderAddress
        receiverContactName
        receiverContactPhone
        receiverContactAddress
        total
        totalProduct
        cashPayment
        debtPayment
        stockStatus
        paymentMethod
        paymentStatus
        assemblyRequired
        fileURLs
        email
        fullEvents {
          state
          name
          createdAt
          createdBy {
            fullname
            type
          }
        }
        orderType
        assemblyRequired
        customerPaymentMethod
        shipperTelephone
        shipperName
        cartID
        cancelReason
        children {
          id
          number
        }
        quantityServiceProduct
        totalServiceProduct
        shareLink
        paymentInfo {
          sellerConfirmPaid
        }
      }
    }
  }
`;

export const GET_SUMMARY_TABS = gql`
  query GetSummaryTabs {
    order {
      totalByStatus {
        all
        waitingForApprovalOrder
        waitingForApprovalStock
        waitingForEnoughStock
        waitingForPayment
        waitingForPackaging
        waitingForExporting
        waitingForAssembly
        waitingForShipping
        delivering
        completed
        canceled
      }
    }
  }
`;

export const GET_CREATED_BY_WITH_IDS = gql`
  query GetCreatedByWithIds($filters: UsersFilters) {
    user {
      pagination(filters: $filters) {
        users {
          id
          email
          fullname
        }
      }
    }
  }
`;

export const GET_ORDER_LOAD_MORE = gql`
  query GetOrdersLoadMore($filters: OrdersFilters!, $pagination: PaginationRequest!, $sort: [OrderSort!]) {
    order {
      pagination(filters: $filters, sort: $sort, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        orders {
          id
          createdAt
          number
          status
          fullEvents {
            state
            name
            createdAt
            createdByID
            createdBy {
              id
              fullname
            }
          }
          seller {
            code
            id
            fullName
            telephone
            address
            email
            city {
              name
            }
            district {
              name
            }
            ward {
              name
            }
          }
          exportedWarehouse {
            id
            name
          }
          items {
            id
            productID
            warrantyType
            warrantyDateUnit
            productWarranty
            product {
              id
              name
              code
              uom {
                id
                name
              }
            }
            inStock
            warrantyNotes {
              id
              code
              warrantyType
              warrantyOnSale
              warrantyPeriodProviders
              warrantyDateUnit
              serialImei
            }
            quantity
          }
        }
      }
    }
  }
`;

export const COUNT_STATUS_ORDER = gql`
  query CountStatusOrder($filters: OrdersFilters) {
    order {
      count(filters: $filters)
    }
  }
`;

export const GET_ORDER_FILTER_LOAD_MORE = gql`
  query GetOrdersFilterLoadMore($filters: OrdersFilters!, $pagination: PaginationRequest!, $sort: [OrderSort!]) {
    order {
      pagination(filters: $filters, sort: $sort, pagination: $pagination) {
        paginationData {
          total
          offset
          limit
        }
        orders {
          id
          number
        }
      }
    }
  }
`;
