import { Descriptions, Skeleton } from "antd";
import "./index.scss";

function CustomDescriptions({
  size = "small",
  column = 1,
  bordered = true,
  title,
  content,
  loading = false,
  contentMapping = [],
  contentStyle,
  labelStyle,
  labelWidth = "30%",
  contentWidth = "70%",
  paddingView,
  ...rest
}) {
  return (
    <Skeleton active loading={loading}>
      <div
        className="custom-descriptions"
        style={{
          "--label-width": labelWidth,
          "--content-width": contentWidth,
          "--padding-view": paddingView,
        }}
      >
        <Descriptions size={size} column={column} title={title} bordered={bordered} {...rest}>
          {content}
          {contentMapping?.map((item, index) => (
            <Descriptions.Item
              key={index}
              span={item?.span}
              label={item?.label}
              contentStyle={contentStyle}
              labelStyle={labelStyle}
            >
              {item?.value || "--"}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>
    </Skeleton>
  );
}

export default CustomDescriptions;
