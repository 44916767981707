import AvatarPlaceHolder from "./avatar-placeholder.png";
import DeleteIcon from "./delete-icon.PNG";
import Google from "./icon_google.png";
import LockedIcon from "./locked-icon.PNG";
import PlaceholderImageFailed from "./Placeholder-image-failed.png";
import QuestionIcon from "./question-icon.png";
import SupportIcon from "./support-icon.png";
import UnlockedIcon from "./unlocked-icon.PNG";

const Img = {
  LockedIcon,
  UnlockedIcon,
  DeleteIcon,
  AvatarPlaceHolder,
  SupportIcon,
  PlaceholderImageFailed,
  QuestionIcon,
  Google,
};

export default Img;
