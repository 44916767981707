import { Form } from "antd";
import { useGetMe } from "hooks/auth/login";
import {
  useGetReportSaleInfo,
  useGetReportSalesManagerInfo,
  useLazyGetReportSalesManagerToken,
  useLazyGetReportSaleToken,
  useReportPermissions,
} from "hooks/report";
import { has } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { endOfMomentToISOString, startOfMomentToISOString, timeRanges } from "utils/dateTime";
import { CHART_TYPE } from "./constants";

//view permissions
// SALE_REPORT_VIEW;
// SALE_MANAGER_REPORT_VIEW;

const convertDateRange = (dateRange) => {
  return dateRange?.length > 0
    ? [startOfMomentToISOString(dateRange.at(0)), endOfMomentToISOString(dateRange.at(1))]
    : [];
};

const setParamsTimeRange = (timeRange, params, set) => {
  if (timeRange) {
    set({
      ...params,
      filter: {
        ...params?.filter,
        timeRange: {
          from: timeRange?.at(0),
          to: timeRange?.at(1),
        },
      },
    });
  }
};

const useSaleChartUi = () => {
  const { t } = useTranslation();
  const { me } = useGetMe();
  const { yesterday } = timeRanges();
  const [form] = Form.useForm();
  const [params, setParams] = useState();
  const [chartType, setChartType] = useState(CHART_TYPE.PERSONAL);
  const [chartData, setChartData] = useState();
  const saleSkip = chartType !== CHART_TYPE.PERSONAL;
  const managerSkip = chartType !== CHART_TYPE.ENTERPRISE;

  const { data: dataSale, loading: loadingSale } = useGetReportSaleInfo(params, saleSkip);
  const { data: dataManager, loading: loadingManger } = useGetReportSalesManagerInfo(params, managerSkip);
  const { handleGetSaleReport } = useLazyGetReportSaleToken();
  const { handleGetManagerReport } = useLazyGetReportSalesManagerToken();
  const reportPermission = useReportPermissions();
  const { canViewSale, canViewManager, canViewSaleChart, loading: loadingPermission } = reportPermission;
  const loadingChart = loadingSale || loadingManger || loadingPermission;

  const GREETING = {
    GOOD_MORNING: t("report.greeting.morning"),
    GOOD_AFTERNOON: t("report.greeting.afternoon"),
    GOOD_EVENING: t("report.greeting.evening"),
    GOOD_NIGHT: t("report.greeting.night"),
    HELLO: t("report.greeting.hello"),
  };

  const renderSubtitle = () => {
    if (chartType === CHART_TYPE.PERSONAL) return t("report.subTitleSale");
    if (chartType === CHART_TYPE.ENTERPRISE) return t("report.subTitleAdmin");
    return "";
  };

  const initValues = {
    type: chartType,
    datePicker: yesterday,
  };

  const handleFormChange = (changedValues, allValues) => {
    // if (has(changedValues, 'datePicker')) {
    //   const datePicker = convertDateRange(allValues.datePicker);
    //   setParamsTimeRange(datePicker, params, setParams);
    // }

    if (has(changedValues, "type")) {
      setChartType(allValues.type);
    }
  };

  const fetchChartToken = async () => {
    if (chartType === CHART_TYPE.PERSONAL) {
      return handleGetSaleReport({ variables: params }).then((res) => {
        const result = res.data?.report?.getSalesReport;
        if (result?.dashboardID === chartData?.dashboardID) {
          return result?.token;
        }
      });
    }

    if (chartType === CHART_TYPE.ENTERPRISE) {
      return handleGetManagerReport({ variables: params }).then((res) => {
        const result = res.data?.report?.getSalesManagerReport;
        if (result?.dashboardID === chartData?.dashboardID) {
          return result?.token;
        }
      });
    }
  };

  const setValuesForm = (value = {}) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, ...value });
  };

  useEffect(() => {
    form.setFieldsValue({ ...initValues });

    // const datePicker = convertDateRange(initValues.datePicker);
    // setParamsTimeRange(datePicker, params, setParams);
  }, []);

  useEffect(() => {
    if (chartType === CHART_TYPE.PERSONAL && !!dataSale) {
      setChartData(dataSale);
    }

    if (chartType === CHART_TYPE.ENTERPRISE && !!dataManager) {
      setChartData(dataManager);
    }
  }, [JSON.stringify(dataSale), JSON.stringify(dataManager)]);

  useEffect(() => {
    if (canViewManager && !canViewSale) {
      setChartType(CHART_TYPE.ENTERPRISE);
      setValuesForm({ type: CHART_TYPE.ENTERPRISE });
    }
  }, [canViewSale, canViewManager]);

  return {
    GREETING,
    me,
    form,
    chartData,
    handleFormChange,
    loadingChart,
    fetchChartToken,
    renderSubtitle,
    reportPermission,
    canViewSaleChart,
  };
};

export default useSaleChartUi;
